<template>
    <div class="application-management">
      <h3>Uygulama Yönetimi</h3>
  
      <h4>{{ isEdit ? "Uygulama Düzenle" : "Yeni Uygulama Ekle" }}</h4>
      <form @submit.prevent="isEdit ? updateApplication() : createApplication()">
        <input v-model="selectedApplication.name" placeholder="Uygulama Adı" required />
        <textarea v-model="selectedApplication.details" placeholder="Detaylar"></textarea>
        <input v-model="selectedApplication.version" placeholder="Versiyon" required />
        <input v-model="selectedApplication.play_store_link" placeholder="Play Store Linki" />
        <input v-model="selectedApplication.app_store_link" placeholder="App Store Linki" />
        <label>
          <input type="checkbox" v-model="selectedApplication.is_active" /> Aktif mi?
        </label>
        <label>
          <input type="checkbox" v-model="selectedApplication.is_login_required" /> Giriş Gerekiyor mu?
        </label>
  
        <button type="submit">{{ isEdit ? "Güncelle" : "Ekle" }}</button>
        <button v-if="isEdit" @click="cancelEdit" type="button">İptal</button>
      </form>
  
      <div class="table-header">
        <h4>Uygulama Listesi</h4>
        <label>
          <input type="checkbox" v-model="showDeleteButtons" /> Silme Butonlarını Göster
        </label>
      </div>
  
      <table class="application-table">
        <thead>
          <tr>
            <th>Adı</th>
            <th>Versiyon</th>
            <th>Aktif</th>
            <th>Giriş Gerekliliği</th>
            <th>İşlemler</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="application in applications" :key="application.application_id">
            <td>{{ application.name }}</td>
            <td>{{ application.version }}</td>
            <td>{{ application.is_active ? "Evet" : "Hayır" }}</td>
            <td>{{ application.is_login_required ? "Evet" : "Hayır" }}</td>
            <td>
              <button @click="editApplication(application)">Düzenle</button>
              <button
                v-if="showDeleteButtons"
                class="delete-button"
                @click="confirmDelete(application.application_id)"
              >
                Sil
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    data() {
      return {
        applications: [], // Uygulama kayıtlarını saklar
        selectedApplication: {
          application_id: null,
          name: "",
          details: "",
          version: "",
          play_store_link: "",
          app_store_link: "",
          is_active: true,
          is_login_required: false,
        },
        isEdit: false,
        showDeleteButtons: false,
      };
    },
    methods: {
      async fetchApplications() {
        try {
          const response = await axios.get("/admin/applications");
          this.applications = response.data.applications || [];
          console.log("Uygulamalar başarıyla alındı:", this.applications);
        } catch (error) {
          console.error("Uygulamalar alınırken hata oluştu:", error);
        }
      },
      async createApplication() {
        try {
          const response = await axios.post("/admin/applications", this.selectedApplication);
          console.log("Uygulama başarıyla eklendi:", response.data);
          this.fetchApplications();
          this.resetForm();
        } catch (error) {
          console.error("Uygulama eklenirken hata oluştu:", error);
        }
      },
      async updateApplication() {
        try {
          const response = await axios.put(
            `/admin/applications/${this.selectedApplication.application_id}`,
            this.selectedApplication
          );
          console.log("Uygulama başarıyla güncellendi:", response.data);
          this.fetchApplications();
          this.resetForm();
        } catch (error) {
          console.error("Uygulama güncellenirken hata oluştu:", error);
        }
      },
      async deleteApplication(application_id) {
        try {
          const response = await axios.delete(`/admin/applications/${application_id}`);
          console.log("Uygulama başarıyla silindi:", response.data);
          this.fetchApplications();
        } catch (error) {
          console.error("Uygulama silinirken hata oluştu:", error);
        }
      },
      editApplication(application) {
        this.selectedApplication = { ...application };
        this.isEdit = true;
      },
      confirmDelete(application_id) {
        if (confirm("Bu uygulamayı silmek istediğinizden emin misiniz?")) {
          this.deleteApplication(application_id);
        }
      },
      resetForm() {
        this.selectedApplication = {
          application_id: null,
          name: "",
          details: "",
          version: "",
          play_store_link: "",
          app_store_link: "",
          is_active: true,
          is_login_required: false,
        };
        this.isEdit = false;
      },
      cancelEdit() {
        this.resetForm();
      },
    },
    mounted() {
      this.fetchApplications();
    },
  };
  </script>
   