<template>
  <div class="education-data-management">
    <h3>Eğitim İçeriği Yönetimi</h3>

    <h4>{{ isEdit ? "Eğitim Verisi Düzenle" : "Yeni Eğitim Verisi Ekle" }}</h4>
    <form @submit.prevent="isEdit ? updateEducationData() : createEducationData()">
      <select v-model="selectedEducationData.education_id" required>
        <option disabled value="">Eğitimi Seçin</option>
        <option v-for="education in educations" :key="education.education_id" :value="education.education_id">
          {{ education.name }}
        </option>
      </select>
      <input v-model="selectedEducationData.title" placeholder="Başlık" />
      <input v-model="selectedEducationData.subtitle" placeholder="Alt Başlık" />
      
      <div>
        <label for="image">Masaüstü Görsel:</label>
        <input class="upload-button" type="file" id="image" @change="handleFileUpload('image', $event)" />
        <img v-if="preview.image" :src="preview.image" alt="Görsel" class="image-preview" />
      </div>

      <div>
        <label for="mobile_image">Mobil Görsel:</label>
        <input class="upload-button" type="file" id="mobile_image" @change="handleFileUpload('mobile_image', $event)" />
        <img v-if="preview.mobile_image" :src="preview.mobile_image" alt="Mobil Görsel" class="image-preview" />
      </div>

      <input v-model="selectedEducationData.serial_no" placeholder="Seri Numarası" required />
      <textarea v-model="selectedEducationData.text_data" placeholder="Metin Verisi"></textarea>
      <button type="submit">{{ isEdit ? "Güncelle" : "Eğitim Verisi Ekle" }}</button>
      <button v-if="isEdit" @click="cancelEdit" type="button">İptal</button>
    </form>

    <div class="table-header">
      <h4>Tüm Eğitim Verileri</h4>
      <label>
        <input type="checkbox" v-model="showDeleteButtons" /> Silme Butonlarını Göster
      </label>
    </div>

    <table class="education-data-table">
      <thead>
        <tr>
          <th>Seri No</th>
          <th>Başlık</th>
          <th>Alt Başlık</th>
          <th>Eğitim</th>
          <th>Masaüstü Görsel</th>
          <th>Mobil Görsel</th>
          <th>Metin Verisi</th>
          <th>Düzenle</th>
          <th v-if="showDeleteButtons">Sil</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="data in educationData" :key="data.data_id">
          <td>{{ data.serial_no }}</td>
          <td>{{ data.title }}</td>
          <td>{{ data.subtitle }}</td>
          <td>{{ getEducationName(data.education_id) }}</td>
          <td>
            <img 
              v-if="data.image_link" 
              :src="data.image_link" 
              alt="Görsel" 
              class="image-preview" 
            />
            <span v-else>Yok</span>
          </td>
          <td>
            <img 
              v-if="data.mobile_image_link" 
              :src="data.mobile_image_link" 
              alt="Mobil Görsel" 
              class="image-preview" 
            />
            <span v-else>Yok</span>
          </td>
          <td>{{ data.text_data }}</td>
          <td><button @click="editEducationData(data)">Düzenle</button></td>
          <td v-if="showDeleteButtons"><button class="deletebutton" @click="confirmDelete(data.data_id)">Sil</button></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      educationData: [],
      educations: [],
      selectedEducationData: {
        data_id: null,
        education_id: '',
        title: '',
        subtitle: '',
        image: null,
        mobile_image: null,
        serial_no: '',
        text_data: '',
      },
      preview: {
        image: null,
        mobile_image: null,
      },
      isEdit: false,
      showDeleteButtons: false,
    };
  },
  methods: {
    getEducationName(id) {
      const education = this.educations.find(ed => ed.education_id === id);
      return education ? education.name : 'Bilinmiyor';
    },

    async fetchEducationData() {
      try {
        const response = await axios.get('/admin/educationdata');  
        this.educationData = response.data.educationData;
      } catch (error) {
        console.error('Eğitim verileri alınırken hata oluştu:', error);
      }
    },

    async fetchEducations() {
      try {
        const response = await axios.get('/admin/educations');
        this.educations = response.data.educations;
      } catch (error) {
        console.error('Eğitimler alınırken hata oluştu:', error);
      }
    },

    handleFileUpload(type, event) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = e => {
        if (type === 'image') {
          this.preview.image = e.target.result;
          this.selectedEducationData.image = file;
        } else if (type === 'mobile_image') {
          this.preview.mobile_image = e.target.result;
          this.selectedEducationData.mobile_image = file;
        }
      };
      reader.readAsDataURL(file);
    },

    async createEducationData() {
      try {
        const formData = new FormData();
        formData.append('education_id', this.selectedEducationData.education_id || '');
        formData.append('title', this.selectedEducationData.title || '');
        formData.append('subtitle', this.selectedEducationData.subtitle || '');
        formData.append('serial_no', this.selectedEducationData.serial_no);
        formData.append('text_data', this.selectedEducationData.text_data || '');
        if (this.selectedEducationData.image) {
          formData.append('image', this.selectedEducationData.image);
        }
        if (this.selectedEducationData.mobile_image) {
          formData.append('mobile_image', this.selectedEducationData.mobile_image);
        }

        await axios.post('/admin/educationdata', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        this.fetchEducationData();
        this.resetForm();
      } catch (error) {
        console.error('Eğitim verisi eklenirken hata oluştu:', error);
      }
    },

    async updateEducationData() {
      try {
        const formData = new FormData();
        formData.append('education_id', this.selectedEducationData.education_id || '');
        formData.append('title', this.selectedEducationData.title || '');
        formData.append('subtitle', this.selectedEducationData.subtitle || '');
        formData.append('serial_no', this.selectedEducationData.serial_no);
        formData.append('text_data', this.selectedEducationData.text_data || '');
        if (this.selectedEducationData.image) {
          formData.append('image', this.selectedEducationData.image);
        }
        if (this.selectedEducationData.mobile_image) {
          formData.append('mobile_image', this.selectedEducationData.mobile_image);
        }

        await axios.put(`/admin/educationdata/${this.selectedEducationData.data_id}`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        this.fetchEducationData();
        this.resetForm();
      } catch (error) {
        console.error('Eğitim verisi güncellenirken hata oluştu:', error);
      }
    },

    editEducationData(data) {
      this.selectedEducationData = {
        ...data,
        image: null,
        mobile_image: null,
      };
      this.preview = {
        image: data.image_link,
        mobile_image: data.mobile_image_link,
      };
      this.isEdit = true;
    },

    async deleteEducationData(id) {
      try {
        await axios.delete(`/admin/educationdata/${id}`);
        this.fetchEducationData();
      } catch (error) {
        console.error('Eğitim verisi silinirken hata oluştu:', error);
      }
    },

    confirmDelete(id) {
      if (confirm('Bu eğitim verisini silmek istediğinizden emin misiniz?')) {
        this.deleteEducationData(id);
      }
    },

    resetForm() {
      this.selectedEducationData = {
        data_id: null,
        education_id: '',
        title: '',
        subtitle: '',
        image: null,
        mobile_image: null,
        serial_no: '',
        text_data: '',
      };
      this.preview = {
        image: null,
        mobile_image: null,
      };
      this.isEdit = false;
    },

    cancelEdit() {
      this.resetForm();
    },
  },
  mounted() {
    this.fetchEducations();
    this.fetchEducationData();
  },
};
</script>
