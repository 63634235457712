<template>
    <div class="question-manager">
      <h3>Soru Yönetimi</h3>
  
      <h4>{{ isEdit ? "Soruyu Düzenle" : "Yeni Soru Ekle" }}</h4>
      <form @submit.prevent="isEdit ? updateQuestion() : createQuestion()">
        <input v-model="selectedQuestion.question_text" placeholder="Soru Metni" required />
        <label>
          Zorluk Seviyesi:
          <select v-model="selectedQuestion.difficulty_level">
            <option value="1">Kolay</option>
            <option value="2">Orta</option>
            <option value="3">Zor</option>
          </select>
        </label>
        <label>
          <input type="checkbox" v-model="selectedQuestion.is_active" /> Aktif
        </label>
        <button type="submit">{{ isEdit ? "Güncelle" : "Soru Ekle" }}</button>
        <button v-if="isEdit" @click="cancelEdit" type="button">İptal</button>
      </form>
  
      <div class="table-header">
        <h4>Tüm Sorular</h4>
        <label>
          <input type="checkbox" v-model="showDeleteButtons" /> Silme Butonlarını Göster
        </label>
      </div>
  
      <table class="question-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Soru Metni</th>
            <th>Zorluk</th>
            <th>Aktif</th>
            <th>Eğitim Bağlantıları</th>
            <th>Cevap Yönetimi</th>
            <th>Düzenle</th>
            <th v-if="showDeleteButtons">Sil</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="question in questions" :key="question.question_id">
            <td>{{ question.question_id }}</td>
            <td>{{ question.question_text }}</td>
            <td>{{ getDifficultyText(question.difficulty_level) }}</td>
            <td>{{ question.is_active ? "Evet" : "Hayır" }}</td>
            <td class="education-checkboxes">
              <div v-for="edu in educations" :key="edu.education_id" class="edu-item">
                <label>
                  <input 
                    type="checkbox"
                    :checked="isQuestionLinkedToEducation(question, edu.education_id)"
                    @change="toggleQuestionEducationLink(question, edu.education_id, $event.target.checked)"
                  />
                  {{ edu.name }}
                </label>
              </div>
            </td>
            <td>
              <button @click="openAnswerModal(question)">Cevapları Yönet</button>
            </td>
            <td><button @click="editQuestion(question)">Düzenle</button></td>
            <td v-if="showDeleteButtons"><button class="deletebutton" @click="confirmDelete(question.question_id)">Sil</button></td>
          </tr>
        </tbody>
      </table>
  
      <!-- Cevap Yönetimi Modal -->
      <div v-if="showAnswerModal" class="modal-overlay">
        <div class="modal">
          <h4>Cevap Yönetimi - Soru ID: {{ selectedQuestionForAnswers.question_id }}</h4>
          
          <h5>Yeni Cevap Ekle</h5>
          <form @submit.prevent="addAnswer()">
            <input v-model="newAnswer.answer_text" placeholder="Cevap Metni" required />
            <label>
              <input type="checkbox" v-model="newAnswer.is_correct" /> Doğru mu?
            </label>
            <button type="submit">Cevap Ekle</button>
          </form>
  
          <h5>Mevcut Cevaplar</h5>
          <table class="answer-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Cevap Metni</th>
                <th>Doğru mu?</th>
                <th>Düzenle</th>
                <th>Sil</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="answer in selectedQuestionForAnswers.answers" :key="answer.question_answer_id">
                <td>{{ answer.question_answer_id }}</td>
                <td>
                  <input v-if="answer.editing" v-model="answer.answer_text" />
                  <span v-else>{{ answer.answer_text }}</span>
                </td>
                <td>
                  <input v-if="answer.editing" type="checkbox" v-model="answer.is_correct" />
                  <span v-else>{{ answer.is_correct ? "Evet" : "Hayır" }}</span>
                </td>
                <td>
                  <button v-if="!answer.editing" @click="answer.editing = true">Düzenle</button>
                  <button v-else @click="updateAnswer(answer)">Kaydet</button>
                </td>
                <td><button @click="deleteAnswer(answer.question_answer_id)">Sil</button></td>
              </tr>
            </tbody>
          </table>
  
          <button @click="closeAnswerModal">Kapat</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        questions: [],
        educations: [],
        selectedQuestion: {
          question_id: null,
          question_text: '',
          difficulty_level: 1,
          is_active: true,
          answers: []
        },
        isEdit: false,
        showDeleteButtons: false,
  
        // Answer Modal
        showAnswerModal: false,
        selectedQuestionForAnswers: {
          question_id: null,
          answers: []
        },
        newAnswer: {
          answer_text: '',
          is_correct: false
        }
      };
    },
    methods: {
      getDifficultyText(level) {
        if (level === 1) return "Kolay";
        if (level === 2) return "Orta";
        if (level === 3) return "Zor";
        return "Bilinmiyor";
      },
      async fetchQuestions() {
        try {
          const response = await axios.get('/admin/questions');
          this.questions = response.data.questions;
        } catch (error) {
          console.error('Sorular alınırken hata:', error);
        }
      },
      async fetchEducations() {
        try {
          const response = await axios.get('/admin/educations');
          this.educations = response.data.educations;
        } catch (error) {
          console.error('Eğitimler alınırken hata:', error);
        }
      },
      async createQuestion() {
        try {
          await axios.post('/admin/questions', {
            question_text: this.selectedQuestion.question_text,
            difficulty_level: this.selectedQuestion.difficulty_level,
            is_active: this.selectedQuestion.is_active
          });
          await this.fetchQuestions();
          this.resetForm();
        } catch (error) {
          console.error('Soru eklenirken hata:', error);
        }
      },
      editQuestion(question) {
        this.selectedQuestion = JSON.parse(JSON.stringify(question));
        this.isEdit = true;
      },
      async updateQuestion() {
        try {
          await axios.put(`/admin/questions/${this.selectedQuestion.question_id}`, {
            question_text: this.selectedQuestion.question_text,
            difficulty_level: this.selectedQuestion.difficulty_level,
            is_active: this.selectedQuestion.is_active
          });
          await this.fetchQuestions();
          this.resetForm();
        } catch (error) {
          console.error('Soru güncellenirken hata:', error);
        }
      },
      async deleteQuestion(id) {
        try {
          await axios.delete(`/admin/questions/${id}`);
          this.fetchQuestions();
        } catch (error) {
          console.error('Soru silinirken hata:', error);
        }
      },
      confirmDelete(id) {
        if (confirm('Bu soruyu silmek istediğinizden emin misiniz?')) {
          this.deleteQuestion(id);
        }
      },
      resetForm() {
        this.selectedQuestion = {
          question_id: null,
          question_text: '',
          difficulty_level: 1,
          is_active: true,
          answers: []
        };
        this.isEdit = false;
        this.newAnswer = {
          answer_text: '',
          is_correct: false
        };
      },
      cancelEdit() {
        this.resetForm();
      },
  
      // Eğitim linkleme
      isQuestionLinkedToEducation(question, education_id) {
        if (!question.educations) return false;
        return question.educations.some(e => e.education_id === education_id);
      },
      async toggleQuestionEducationLink(question, education_id, checked) {
        try {
          if (checked) {
            await axios.post(`/admin/questions/${question.question_id}/link-education`, { education_id });
          } else {
            await axios.post(`/admin/questions/${question.question_id}/unlink-education`, { education_id });
          }
          // Link / Unlink sonrası soruları yeniden çekerek güncel veriyi alıyoruz.
          this.fetchQuestions();
        } catch (error) {
          console.error('Eğitim bağlantısı güncellenirken hata:', error);
        }
      },
  
      // Cevap Yönetimi (Modal)
      openAnswerModal(question) {
        // Seçili soruyu kopyalıyoruz (cevaplarla birlikte)
        this.selectedQuestionForAnswers = JSON.parse(JSON.stringify(question));
        if (this.selectedQuestionForAnswers.answers) {
          this.selectedQuestionForAnswers.answers.forEach(a => a.editing = false);
        }
        this.newAnswer = { answer_text: '', is_correct: false };
        this.showAnswerModal = true;
      },
      closeAnswerModal() {
        this.showAnswerModal = false;
      },
      async addAnswer() {
        if (!this.selectedQuestionForAnswers.question_id) return;
        try {
          const response = await axios.post(`/admin/questions/${this.selectedQuestionForAnswers.question_id}/answers`, {
            answer_text: this.newAnswer.answer_text,
            is_correct: this.newAnswer.is_correct
          });
          this.selectedQuestionForAnswers.answers.push({
            ...response.data.answer,
            editing: false
          });
          this.newAnswer = { answer_text: '', is_correct: false };
          // Ana listeyi güncellemek için:
          this.fetchQuestions();
        } catch (error) {
          console.error('Cevap eklenirken hata:', error);
        }
      },
      async updateAnswer(answer) {
        try {
          await axios.put(`/admin/question-answers/${answer.question_answer_id}`, {
            answer_text: answer.answer_text,
            is_correct: answer.is_correct
          });
          answer.editing = false;
          // Ana listeyi güncelle:
          this.fetchQuestions();
        } catch (error) {
          console.error('Cevap güncellenirken hata:', error);
        }
      },
      async deleteAnswer(answer_id) {
        if (!confirm('Bu cevabı silmek istediğinizden emin misiniz?')) return;
        try {
          await axios.delete(`/admin/question-answers/${answer_id}`);
          this.selectedQuestionForAnswers.answers = this.selectedQuestionForAnswers.answers.filter(a => a.question_answer_id !== answer_id);
          // Ana listeyi güncelle:
          this.fetchQuestions();
        } catch (error) {
          console.error('Cevap silinirken hata:', error);
        }
      }
    },
    async mounted() {
      await this.fetchEducations();
      await this.fetchQuestions();
    }
  };
  </script>
  
  <style>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right:0;
    bottom:0;
    background: rgba(0,0,0,0.9);
  }
  
  .modal {
    background: #000;
    padding:20px;
    margin: 50px auto;
    max-width: 720px;
    border-radius: 10px;
    border: 1px solid #022121;
  }
  
  .edu-item {
    display: inline-block;
    margin-right: 10px;
  }
  </style>
  