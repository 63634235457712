<template>
    <div class="user-calendar-settings-manager">
      <h3>Kullanıcı Takvim Ayarları Yönetimi</h3>
      <input
        v-model="searchQuery"
        @input="filterUsers"
        placeholder="Kullanıcı adı veya ID ara"
      />
      <ul v-if="filteredUsers.length > 0" class="user-suggestions">
        <li
          v-for="user in filteredUsers"
          :key="user.user_id"
          @click="selectUser(user)"
        >
          {{ user.user_name }} (ID: {{ user.user_id }})
        </li>
      </ul>
  
      <div v-if="selectedUser">
        <h4>Takvim Ayarları - {{ selectedUser.user_name }}</h4>
        <div v-if="calendarSetting">
          <label>Çalışma Saatleri</label>
          <textarea v-model="calendarSetting.working_hours" placeholder="JSON formatında girin"></textarea>
          <label>Tatil Günleri</label>
          <textarea v-model="calendarSetting.holidays" placeholder="JSON formatında girin"></textarea>
          <label>Herkese Açık mı?</label>
          <input type="checkbox" v-model="calendarSetting.is_public" />
          <button @click="updateCalendarSetting">Güncelle</button>
          <button @click="deleteCalendarSetting">Sil</button>
        </div>
        <div v-else>
          <p>Bu kullanıcıya ait takvim ayarı bulunamadı.</p>
          <button @click="addCalendarSetting">Yeni Takvim Ayarı Ekle</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    data() {
      return {
        users: [],
        filteredUsers: [],
        searchQuery: "",
        selectedUser: null,
        calendarSetting: null,
      };
    },
    methods: {
      async fetchUsers() {
        try {
          const response = await axios.get("/admin/mainusers");
          this.users = response.data;
        } catch (error) {
          console.error("Kullanıcılar alınırken hata oluştu:", error);
        }
      },
      async fetchCalendarSetting(userId) {
        try {
          const response = await axios.get(`/admin/cl/calendarsettings/user/${userId}`);
          this.calendarSetting = response.data;
        } catch (error) {
          console.error("Takvim ayarı alınırken hata oluştu:", error);
          this.calendarSetting = null;
        }
      },
      async addCalendarSetting() {
        try {
          const newSetting = {
            user_id: this.selectedUser.user_id,
            working_hours: {},
            holidays: {},
            is_public: false,
          };
          await axios.post("/admin/cl/calendarsettings", newSetting);
          alert("Takvim ayarı başarıyla eklendi.");
          this.fetchCalendarSetting(this.selectedUser.user_id);
        } catch (error) {
          console.error("Takvim ayarı eklenirken hata oluştu:", error);
        }
      },
      async updateCalendarSetting() {
        try {
          await axios.put(`/admin/cl/calendarsettings/${this.calendarSetting.id}`, this.calendarSetting);
          alert("Takvim ayarı başarıyla güncellendi.");
        } catch (error) {
          console.error("Takvim ayarı güncellenirken hata oluştu:", error);
        }
      },
      async deleteCalendarSetting() {
        if (confirm("Bu takvim ayarını silmek istediğinize emin misiniz?")) {
          try {
            await axios.delete(`/admin/cl/calendarsettings/${this.calendarSetting.id}`);
            alert("Takvim ayarı başarıyla silindi.");
            this.calendarSetting = null;
          } catch (error) {
            console.error("Takvim ayarı silinirken hata oluştu:", error);
          }
        }
      },
      filterUsers() {
        if (this.searchQuery.trim() === "") {
          this.filteredUsers = [];
          return;
        }
        this.filteredUsers = this.users.filter(user => {
          const userName = user.user_name?.toLowerCase() || "";
          const userId = user.user_id?.toString() || "";
          return (
            userName.includes(this.searchQuery.toLowerCase()) ||
            userId.includes(this.searchQuery)
          );
        });
      },
      async selectUser(user) {
        this.selectedUser = user;
        this.searchQuery = "";
        this.filteredUsers = [];
        await this.fetchCalendarSetting(user.user_id);
      },
    },
    mounted() {
      this.fetchUsers();
    },
  };
  </script>
  
  <style scoped>
  .user-suggestions {
    list-style: none;
    padding: 0;
  }
  
  .user-suggestions li {
    cursor: pointer;
    padding: 5px;
    border-bottom: 1px solid #242424;
  }
  
  .user-suggestions li:hover {
    background-color: #000000;
  }
  </style>
  