<template>
  <div class="category-list">
    <h3>Kategori Listesi</h3>
    <table>
      <thead>
        <tr>
          <th>ID</th>
          <th>Kategori Adı</th>
          <th>Tür</th>
          <th>Space ID</th>
          <th>Kullanıcı ID</th>
          <th>Oluşturulma Tarihi</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="category in categories" :key="category.id">
          <td>{{ category.id }}</td>
          <td>{{ category.name }}</td>
          <td>{{ category.type }}</td>
          <td>{{ category.space_id }}</td>
          <td>{{ category.user_id }}</td>
          <td>{{ formatDate(category.created_at) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      categories: [],
    };
  },
  methods: {
    async fetchCategories() {
      try {
        const response = await axios.get("/admin/user/mtcategories");
        this.categories = response.data.data;
      } catch (error) {
        console.error("Kategoriler alınırken hata oluştu:", error);
      }
    },
    formatDate(date) {
      return new Date(date).toLocaleString();
    },
  },
  mounted() {
    this.fetchCategories();
  },
};
</script> 
