<template>
  <div>
    <h3>Kullanıcı Kategorileri Yönetimi</h3>
    <input
      v-model="searchQuery"
      placeholder="Kullanıcı ID veya Adı ara"
      @input="filterUsers"
    />
    <ul v-if="filteredUsers.length">
      <li v-for="user in filteredUsers" :key="user.user_id" @click="selectUser(user)">
        {{ user.user_name }} (ID: {{ user.user_id }})
      </li>
    </ul>

    <div v-if="selectedUser">
      <h4>Kategoriler - {{ selectedUser.user_name }}</h4>
      <table>
        <thead>
          <tr>
            <th>Ad</th>
            <th>Tür</th>
            <th>İşlemler</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="category in categories" :key="category.id">
            <td>
              <input
                v-model="category.name"
                @change="updateCategory(category)"
                placeholder="Kategori Adı"
              />
            </td>
            <td>{{ category.type }}</td>
            <td>
              <button @click="deleteCategory(category.id)">Sil</button>
            </td>
          </tr>
        </tbody>
      </table>

      <h4>Yeni Kategori Ekle</h4>
      <form @submit.prevent="addCategory">
        <label>Space</label>
        <select v-model="newCategory.space_id" required>
          <option value="">Seç</option>
          <option v-for="space in userSpaces" :key="space.id" :value="space.id">
            {{ space.name }}
          </option>
        </select>

        <label>Ad</label>
        <input v-model="newCategory.name" placeholder="Kategori Adı" required />

        <label>Tür</label>
        <select v-model="newCategory.type" required>
          <option value="income">Gelir</option>
          <option value="expense">Gider</option>
        </select>

        <button type="submit">Ekle</button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      users: [],
      searchQuery: "",
      filteredUsers: [],
      selectedUser: null,
      categories: [],
      userSpaces: [],
      newCategory: {
        space_id: "",
        name: "",
        type: "income",
      },
    };
  },
  methods: {
    async fetchUsers() {
      const response = await axios.get("/admin/mainusers");
      this.users = response.data;
    },
    async fetchUserCategories(userId) {
      const response = await axios.get(`/admin/user/${userId}/categories`);
      this.categories = response.data.data;
    },
    async fetchUserSpaces(userId) {
      const response = await axios.get(`/admin/user/${userId}/spaces`);
      this.userSpaces = response.data.data;
    },
    async addCategory() {
      try {
        await axios.post(`/admin/user/${this.selectedUser.user_id}/categories`, this.newCategory);
        this.fetchUserCategories(this.selectedUser.user_id);
        this.newCategory = { space_id: "", name: "", type: "income" };
      } catch (error) {
        console.error("Kategori eklenirken hata oluştu:", error);
      }
    },
    async updateCategory(category) {
      try {
        await axios.put(`/admin/user/mtcategories/${category.id}`, { name: category.name });
      } catch (error) {
        console.error("Kategori güncellenirken hata oluştu:", error);
      }
    },
    async deleteCategory(categoryId) {
      if (confirm("Bu kategoriyi silmek istediğinizden emin misiniz?")) {
        try {
          await axios.delete(`/admin/user/mtcategories/${categoryId}`);
          this.fetchUserCategories(this.selectedUser.user_id);
        } catch (error) {
          console.error("Kategori silinirken hata oluştu:", error);
        }
      }
    },
    filterUsers() {
      this.filteredUsers = this.users.filter(user =>
        user.user_name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
        user.user_id.toString().includes(this.searchQuery)
      );
    },
    selectUser(user) {
      this.selectedUser = user;
      this.fetchUserCategories(user.user_id);
      this.fetchUserSpaces(user.user_id);
    },
  },
  mounted() {
    this.fetchUsers();
  },
};
</script>
