<template>
    <div class="user-customers-manager">
      <h3>Kullanıcı Müşteri Yönetimi</h3>
      <input
        v-model="searchQuery"
        @input="filterUsers"
        placeholder="Kullanıcı adı veya ID ara"
      />
      <ul v-if="filteredUsers.length > 0" class="user-suggestions">
        <li
          v-for="user in filteredUsers"
          :key="user.user_id"
          @click="selectUser(user)"
        >
          {{ user.user_name }} (ID: {{ user.user_id }})
        </li>
      </ul>
  
      <div v-if="selectedUser">
        <h4>Müşteriler - {{ selectedUser.user_name }}</h4>
        <table class="customers-table">
          <thead>
            <tr>
              <th>Adı</th>
              <th>Email</th>
              <th>Telefon</th>
              <th>Notlar</th>
              <th>İşlemler</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="customer in customers" :key="customer.id">
              <td>
                <input v-model="customer.name" />
              </td>
              <td>
                <input v-model="customer.email" />
              </td>
              <td>
                <input v-model="customer.phone" />
              </td>
              <td>
                <textarea v-model="customer.notes"></textarea>
              </td>
              <td>
                <button @click="updateCustomer(customer)">Güncelle</button>
                <button class="delete" @click="deleteCustomer(customer.id)">Sil</button>
              </td>
            </tr>
          </tbody>
        </table>
  
        <h4>Yeni Müşteri Ekle</h4>
        <form @submit.prevent="addCustomer">
          <label>Adı</label>
          <input v-model="newCustomer.name" placeholder="Adı" />
          <label>Email</label>
          <input v-model="newCustomer.email" placeholder="Email" />
          <label>Telefon</label>
          <input v-model="newCustomer.phone" placeholder="Telefon" />
          <label>Notlar</label>
          <textarea v-model="newCustomer.notes" placeholder="Notlar"></textarea>
          <button type="submit">Müşteri Ekle</button>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    data() {
      return {
        users: [],
        filteredUsers: [],
        searchQuery: "",
        selectedUser: null,
        customers: [],
        newCustomer: {
          name: "",
          email: "",
          phone: "",
          notes: "",
        },
      };
    },
    methods: {
      async fetchUsers() {
        try {
          const response = await axios.get("/admin/mainusers");
          this.users = response.data;
        } catch (error) {
          console.error("Kullanıcılar alınırken hata oluştu:", error);
        }
      },
      async fetchCustomers(userId) {
        try {
          const response = await axios.get(`/admin/cl/customers/user/${userId}`);
          this.customers = response.data;
        } catch (error) {
          console.error("Müşteriler alınırken hata oluştu:", error);
        }
      },
      async addCustomer() {
        try {
          const customerData = {
            user_id: this.selectedUser.user_id,
            name: this.newCustomer.name,
            email: this.newCustomer.email,
            phone: this.newCustomer.phone,
            notes: this.newCustomer.notes,
          };
          await axios.post("/admin/cl/customers", customerData);
          alert("Müşteri başarıyla eklendi.");
          this.fetchCustomers(this.selectedUser.user_id);
        } catch (error) {
          console.error("Müşteri eklenirken hata oluştu:", error);
        }
      },
      async updateCustomer(customer) {
        try {
          await axios.put(`/admin/cl/customers/${customer.id}`, {
            name: customer.name,
            email: customer.email,
            phone: customer.phone,
            notes: customer.notes,
          });
          alert("Müşteri başarıyla güncellendi.");
        } catch (error) {
          console.error("Müşteri güncellenirken hata oluştu:", error);
        }
      },
      async deleteCustomer(customerId) {
        if (confirm("Bu müşteriyi silmek istediğinize emin misiniz?")) {
          try {
            await axios.delete(`/admin/cl/customers/${customerId}`);
            this.customers = this.customers.filter(customer => customer.id !== customerId);
            alert("Müşteri başarıyla silindi.");
          } catch (error) {
            console.error("Müşteri silinirken hata oluştu:", error);
          }
        }
      },
      filterUsers() {
        if (this.searchQuery.trim() === "") {
          this.filteredUsers = [];
          return;
        }
        this.filteredUsers = this.users.filter(user => {
          const userName = user.user_name?.toLowerCase() || "";
          const userId = user.user_id?.toString() || "";
          return (
            userName.includes(this.searchQuery.toLowerCase()) ||
            userId.includes(this.searchQuery)
          );
        });
      },
      async selectUser(user) {
        this.selectedUser = user;
        this.searchQuery = "";
        this.filteredUsers = [];
        await this.fetchCustomers(user.user_id);
      },
    },
    mounted() {
      this.fetchUsers();
    },
  };
  </script>
  
  <style scoped>
  .user-suggestions {
    list-style: none;
    padding: 0;
  }
  
  .user-suggestions li {
    cursor: pointer;
    padding: 5px;
    border-bottom: 1px solid #242424;
  }
  
  .user-suggestions li:hover {
    background-color: #000000;
  }
   
  </style>
  