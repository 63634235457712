<template>
    <div class="default-service-manager">
      <h3>Varsayılan Hizmet Yönetimi</h3>
  
      <h4>{{ isEdit ? "Hizmet Düzenle" : "Yeni Hizmet Ekle" }}</h4>
      <form @submit.prevent="isEdit ? updateService() : createService()">
        <input v-model="selectedService.name" placeholder="Hizmet Adı" required />
        <textarea v-model="selectedService.description" placeholder="Hizmet Açıklaması" rows="3"></textarea>
        <label>Süre (dakika)</label>
        <input v-model.number="selectedService.duration" placeholder="Süre (dakika)" type="number" required />
        <label>Fiyat</label>
        <input v-model.number="selectedService.price" placeholder="Fiyat" type="number" required />
        <label>Para Birimi</label>
        <select v-model="selectedService.currency_id" required>
          <option value="" disabled selected>Para Birimi Seç</option>
          <option v-for="currency in currencies" :key="currency.currency_id" :value="currency.currency_id">
            {{ currency.currency_name }} ({{ currency.currency_symbol }})
          </option>
        </select>
  
        <button type="submit">{{ isEdit ? "Güncelle" : "Ekle" }}</button>
        <button v-if="isEdit" type="button" @click="cancelEdit">İptal</button>
      </form>
  
      <div class="table-header">
        <h4>Varsayılan Hizmetler</h4>
      </div>
  
      <table class="default-service-table">
        <thead>
          <tr>
            <th>Hizmet Adı</th>
            <th>Açıklama</th>
            <th>Süre (dk)</th>
            <th>Fiyat</th>
            <th>İşlemler</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="service in defaultServices" :key="service.id">
            <td>{{ service.name }}</td>
            <td>{{ service.description }}</td>
            <td>{{ service.duration }}</td>
            <td>
              {{ service.price }} 
              {{
                currencies.find(currency => currency.currency_id === service.currency_id)?.currency_symbol || ""
              }}
            </td>
            <td>
              <button @click="editService(service)">Düzenle</button>
              <button class="delete" @click="confirmDelete(service.id)">Sil</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    data() {
      return {
        defaultServices: [],
        currencies: [],
        selectedService: {
          id: null,
          name: "",
          description: "",
          duration: 0,
          price: 0,
          currency_id: null,
        },
        isEdit: false,
      };
    },
    methods: {
      async fetchCurrencies() {
        try {
          const response = await axios.get("/auth/currencies");
          this.currencies = response.data;
        } catch (error) {
          console.error("Para birimleri alınırken hata oluştu:", error);
        }
      },
      async fetchDefaultServices() {
        try {
          const response = await axios.get("/admin/cl/defaultservices");
          this.defaultServices = response.data;
        } catch (error) {
          console.error("Hizmetler alınırken hata oluştu:", error);
        }
      },
      async createService() {
        try {
          await axios.post("/admin/cl/defaultservices", this.selectedService);
          this.fetchDefaultServices();
          this.resetForm();
        } catch (error) {
          console.error("Hizmet eklenirken hata oluştu:", error);
        }
      },
      async updateService() {
        try {
          await axios.put(`/admin/cl/defaultservices/${this.selectedService.id}`, this.selectedService);
          this.fetchDefaultServices();
          this.resetForm();
        } catch (error) {
          console.error("Hizmet güncellenirken hata oluştu:", error);
        }
      },
      async deleteService(id) {
        try {
          await axios.delete(`/admin/cl/defaultservices/${id}`);
          this.fetchDefaultServices();
        } catch (error) {
          console.error("Hizmet silinirken hata oluştu:", error);
        }
      },
      confirmDelete(id) {
        if (confirm("Bu hizmeti silmek istediğinizden emin misiniz?")) {
          this.deleteService(id);
        }
      },
      editService(service) {
        this.selectedService = { ...service };
        this.isEdit = true;
      },
      resetForm() {
        this.selectedService = {
          id: null,
          name: "",
          description: "",
          duration: 0,
          price: 0,
          currency_id: null,
        };
        this.isEdit = false;
      },
      cancelEdit() {
        this.resetForm();
      },
    },
    mounted() {
      this.fetchDefaultServices();
      this.fetchCurrencies();
    },
  };
  </script>
  
  <style scoped>
  .default-service-manager {
    padding: 20px;
  }
  
  .table-header {
    margin-top: 20px;
  }
  </style>
  