<template>
    <div class="services-manager">
      <h3>Tüm Hizmetler (kullanıcıların)</h3>
      <table class="services-table">
        <thead>
          <tr>
            <th>Hizmet Adı</th>
            <th>Açıklama</th>
            <th>Süre (dk)</th>
            <th>Fiyat</th>
            <th>Kullanıcı ID</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="service in services" :key="service.id">
            <td>{{ service.name }}</td>
            <td>{{ service.description }}</td>
            <td>{{ service.duration }}</td>
            <td>
              {{ service.price }} {{ service.currency_symbol || "" }}
            </td>
            <td>{{ service.user_id }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    data() {
      return {
        services: [], 
      };
    },
    methods: {
      async fetchServices() {
        try {
          const response = await axios.get("/admin/cl/alluserservices");
          this.services = response.data;
        } catch (error) {
          console.error("Hizmetler alınırken hata oluştu:", error);
        }
      },
    },
    mounted() {
      this.fetchServices();
    },
  };
  </script>
  
  <style scoped> 
  </style>
  