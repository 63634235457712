<template>
  <div class="subcategory-management">
    <h3>Alt Kategoriler</h3>

    <h4>{{ isEdit ? "Alt Kategori Düzenle" : "Yeni Alt Kategori Ekle" }}</h4>
    <form @submit.prevent="isEdit ? updateSubcategory() : createSubcategory()">
      <input v-model="selectedSubcategory.name" placeholder="Alt Kategori Adı" required />
      <input v-model="selectedSubcategory.description" placeholder="Açıklama" /> 
      <select v-model="selectedSubcategory.main_category_id" required>
        <option disabled value="">Ana Kategoriyi Seçin</option>
        <option v-for="category in categories" :key="category.id" :value="category.id">
          {{ category.name }}
        </option>
      </select>

      <div>
        <label for="mobil-image">Mobil Görsel:</label>
        <input class="upload-button" type="file" id="mobil-image" @change="handleFileUpload('mobil_image', $event)" />
        <img v-if="preview.mobil_image" :src="preview.mobil_image" alt="Mobil Görsel" class="image-preview" />
      </div>
      <div>
        <label for="web-image">Web Görsel:</label>
        <input class="upload-button" type="file" id="web-image" @change="handleFileUpload('web_image', $event)" />
        <img v-if="preview.web_image" :src="preview.web_image" alt="Web Görsel" class="image-preview" />
      </div>

      <button type="submit">{{ isEdit ? "Güncelle" : "Alt Kategori Ekle" }}</button>
      <button v-if="isEdit" @click="cancelEdit" type="button">İptal</button>
    </form>

    <div class="table-header">
      <h4>Tüm Alt Kategoriler</h4>
      <label>
        <input type="checkbox" v-model="showDeleteButtons" /> Silme Butonlarını Göster
      </label>
    </div>

    <table class="subcategory-table">
      <thead>
        <tr>
          <th>Alt Kategori Adı</th>
          <th>Açıklama</th>
          <th>Ana Kategori</th>
          <th>Mobil Görsel</th>
          <th>Web Görsel</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="subcategory in subcategories" :key="subcategory.id">
          <td>{{ subcategory.name }}</td>
          <td>{{ subcategory.description }}</td>
          <td>{{ getCategoryName(subcategory.main_category_id) }}</td>
          <td>
            <img 
              v-if="subcategory.mobil_image" 
              :src="subcategory.mobil_image" 
              alt="Mobil Görsel" 
              class="image-preview"
            />
            <span v-else>Yok</span>
          </td>
          <td>
            <img 
              v-if="subcategory.web_image" 
              :src="subcategory.web_image" 
              alt="Web Görsel" 
              class="image-preview"
            />
            <span v-else>Yok</span>
          </td>
          <td>
            <button @click="editSubcategory(subcategory)">Düzenle</button>
            <button v-if="showDeleteButtons" class="deletebutton" @click="confirmDelete(subcategory.id)">Sil</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      subcategories: [],
      categories: [],
      selectedSubcategory: {
        id: null,
        name: '',
        description: '',
        main_category_id: '',
        mobil_image: null,
        web_image: null,
      },
      preview: {
        mobil_image: null,
        web_image: null,
      },
      isEdit: false,
      showDeleteButtons: false,
    };
  },
  methods: {
    getCategoryName(id) {
      const category = this.categories.find(cat => cat.id === id);
      return category ? category.name : 'Bilinmiyor';
    },

    async fetchSubcategories() {
      try {
        const response = await axios.get('/promo/sub-categories');
        this.subcategories = response.data.subCategories;
      } catch (error) {
        console.error('Alt kategoriler alınırken hata oluştu:', error);
      }
    },

    async fetchCategories() {
      try {
        const response = await axios.get('/promo/main-categories');
        this.categories = response.data.mainCategories;
      } catch (error) {
        console.error('Kategoriler alınırken hata oluştu:', error);
      }
    },

    handleFileUpload(type, event) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = e => {
        if (type === 'mobil_image') {
          this.preview.mobil_image = e.target.result;
          this.selectedSubcategory.mobil_image = file;
        } else if (type === 'web_image') {
          this.preview.web_image = e.target.result;
          this.selectedSubcategory.web_image = file;
        }
      };
      reader.readAsDataURL(file);
    },

    async createSubcategory() {
      try {
        const formData = new FormData();
        formData.append('name', this.selectedSubcategory.name);
        formData.append('description', this.selectedSubcategory.description);
        formData.append('main_category_id', this.selectedSubcategory.main_category_id);
        if (this.selectedSubcategory.mobil_image) {
          formData.append('mobil_image', this.selectedSubcategory.mobil_image);
        }
        if (this.selectedSubcategory.web_image) {
          formData.append('web_image', this.selectedSubcategory.web_image);
        }

        await axios.post('/promo/sub-category', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        this.fetchSubcategories();
        this.resetForm();
      } catch (error) {
        console.error('Alt kategori eklenirken hata oluştu:', error);
      }
    },

    async updateSubcategory() {
      try {
        const formData = new FormData();
        formData.append('name', this.selectedSubcategory.name);
        formData.append('description', this.selectedSubcategory.description);
        formData.append('main_category_id', this.selectedSubcategory.main_category_id);
        if (this.selectedSubcategory.mobil_image) {
          formData.append('mobil_image', this.selectedSubcategory.mobil_image);
        }
        if (this.selectedSubcategory.web_image) {
          formData.append('web_image', this.selectedSubcategory.web_image);
        }

        await axios.put(`/promo/sub-category/${this.selectedSubcategory.id}`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        this.fetchSubcategories();
        this.resetForm();
      } catch (error) {
        console.error('Alt kategori güncellenirken hata oluştu:', error);
      }
    },

    editSubcategory(subcategory) {
      this.selectedSubcategory = {
        ...subcategory,
        mobil_image: null,
        web_image: null,
      };
      this.preview = {
        mobil_image: subcategory.mobil_image,
        web_image: subcategory.web_image,
      };
      this.isEdit = true;
    },

    async deleteSubcategory(id) {
      try {
        await axios.delete(`/promo/sub-category/${id}`);
        this.fetchSubcategories();
      } catch (error) {
        console.error('Alt kategori silinirken hata oluştu:', error);
      }
    },

    confirmDelete(id) {
      if (confirm('Bu alt kategoriyi silmek istediğinizden emin misiniz?')) {
        this.deleteSubcategory(id);
      }
    },

    resetForm() {
      this.selectedSubcategory = {
        id: null,
        name: '',
        description: '',
        main_category_id: '',
        mobil_image: null,
        web_image: null,
      };
      this.preview = {
        mobil_image: null,
        web_image: null,
      };
      this.isEdit = false;
    },

    cancelEdit() {
      this.resetForm();
    },
  },
  mounted() {
    this.fetchCategories();
    this.fetchSubcategories();
  },
};
</script>
 