<template>
    <div class="user-shared-calendars-manager">
      <h3>Kullanıcı Paylaşım Yönetimi</h3>
      <input
        v-model="searchQuery"
        @input="filterUsers"
        placeholder="Kullanıcı adı veya ID ara"
      />
      <ul v-if="filteredUsers.length > 0" class="user-suggestions">
        <li
          v-for="user in filteredUsers"
          :key="user.user_id"
          @click="selectUser(user)"
        >
          {{ user.user_name }} (ID: {{ user.user_id }})
        </li>
      </ul>
  
      <div v-if="selectedUser">
        <h4>Paylaşımlar - {{ selectedUser.user_name }}</h4>
        <div v-if="sharedCalendars.length > 0">
          <table class="shared-calendars-table">
            <thead>
              <tr>
                <th>Paylaşılan Kullanıcı ID</th>
                <th>Erişim Seviyesi</th>
                <th>İşlemler</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="calendar in sharedCalendars" :key="calendar.id">
                <td>{{ calendar.shared_with_user_id }}</td>
                <td>
                  <select v-model="calendar.access_level">
                    <option value="view">Görünüm</option>
                    <option value="edit">Düzenleme</option>
                  </select>
                </td>
                <td>
                  <button @click="updateSharedCalendar(calendar)">Güncelle</button>
                  <button class="delete" @click="deleteSharedCalendar(calendar.id)">Sil</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else>
          <p>Bu kullanıcıya ait herhangi bir paylaşım bulunamadı.</p>
        </div>
  
        <h4>Yeni Paylaşım Ekle</h4>
        <form @submit.prevent="addSharedCalendar">
          <label>Paylaşılan Kullanıcı</label>
          <input
            type="text"
            v-model="searchSharedWithQuery"
            @input="filterSharedWithUsers"
            placeholder="Kullanıcı ara"
          />
          <select v-model="newSharedCalendar.shared_with_user_id">
            <option value="" disabled>Bir kullanıcı seçin</option>
            <option
              v-for="user in filteredSharedWithUsers"
              :key="user.user_id"
              :value="user.user_id"
            >
              {{ user.user_name }} (ID: {{ user.user_id }})
            </option>
          </select>
        
          <label>Erişim Seviyesi</label>
          <select v-model="newSharedCalendar.access_level">
            <option value="view">Görünüm</option>
            <option value="edit">Düzenleme</option>
          </select>
          <button type="submit">Paylaşım Ekle</button>
        </form>
        
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    data() {
      return {
        users: [], 
        filteredUsers: [], 
        searchSharedWithQuery: "",
        searchQuery: "", 
        selectedUser: null, 
        sharedCalendars: [], 
        newSharedCalendar: {
          shared_with_user_id: "",
          access_level: "view",
        },
      };
    },
    methods: {
        async fetchUsers() {
           try {
             const response = await axios.get("/admin/mainusers");
             this.users = response.data;
             this.filteredSharedWithUsers = this.users; 
           } catch (error) {
             console.error("Kullanıcılar alınırken hata oluştu:", error);
           }
         },
         filterSharedWithUsers() {
           if (this.searchSharedWithQuery.trim() === "") {
             this.filteredSharedWithUsers = this.users;
             return;
           }
           this.filteredSharedWithUsers = this.users.filter(user => {
             const userName = user.user_name?.toLowerCase() || "";
             const userId = user.user_id?.toString() || "";
             return (
               userName.includes(this.searchSharedWithQuery.toLowerCase()) ||
               userId.includes(this.searchSharedWithQuery)
             );
           });
         },
      async fetchSharedCalendars(userId) {
        try {
          const response = await axios.get(`/admin/cl/sharedcalendars/owner/${userId}`);
          this.sharedCalendars = response.data;
        } catch (error) {
          console.error("Paylaşımlar alınırken hata oluştu:", error);
          this.sharedCalendars = [];
        }
      },
      async addSharedCalendar() {
        try {
          const calendarData = {
            calendar_owner_id: this.selectedUser.user_id,
            shared_with_user_id: this.newSharedCalendar.shared_with_user_id,
            access_level: this.newSharedCalendar.access_level,
          };
          await axios.post("/admin/cl/sharedcalendars", calendarData);
          alert("Paylaşım başarıyla eklendi.");
          this.fetchSharedCalendars(this.selectedUser.user_id);
        } catch (error) {
          console.error("Paylaşım eklenirken hata oluştu:", error);
        }
      },
      async updateSharedCalendar(calendar) {
        try {
          await axios.put(`/admin/cl/sharedcalendars/${calendar.id}`, {
            access_level: calendar.access_level,
          });
          alert("Paylaşım başarıyla güncellendi.");
        } catch (error) {
          console.error("Paylaşım güncellenirken hata oluştu:", error);
        }
      },
      async deleteSharedCalendar(calendarId) {
        if (confirm("Bu paylaşımı silmek istediğinize emin misiniz?")) {
          try {
            await axios.delete(`/admin/cl/sharedcalendars/${calendarId}`);
            this.sharedCalendars = this.sharedCalendars.filter(
              calendar => calendar.id !== calendarId
            );
            alert("Paylaşım başarıyla silindi.");
          } catch (error) {
            console.error("Paylaşım silinirken hata oluştu:", error);
          }
        }
      },
      filterUsers() {
        if (this.searchQuery.trim() === "") {
          this.filteredUsers = [];
          return;
        }
        this.filteredUsers = this.users.filter(user => {
          const userName = user.user_name?.toLowerCase() || "";
          const userId = user.user_id?.toString() || "";
          return (
            userName.includes(this.searchQuery.toLowerCase()) ||
            userId.includes(this.searchQuery)
          );
        });
      },
      async selectUser(user) {
        this.selectedUser = user;
        this.searchQuery = "";
        this.filteredUsers = [];
        await this.fetchSharedCalendars(user.user_id);
      },
    },
    mounted() {
      this.fetchUsers();
    },
  };
  </script>
  
  <style scoped>
  .user-suggestions {
    list-style: none;
    padding: 0;
  }
  
  .user-suggestions li {
    cursor: pointer;
    padding: 5px;
    border-bottom: 1px solid #242424;
  }
  
  .user-suggestions li:hover {
    background-color: #000000;
  }
   
  </style>
  