<template>
    <div class="tag-management">
      <h3>Etiket Yönetimi</h3>
      <div>
        <input v-model="newTagName" type="text" placeholder="Yeni etiket adı" />
        <button @click="addTag">Ekle</button>
      </div>
      <table>
        <thead>
          <tr>
            <th>Etiket Adı</th>
            <th>İşlemler</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="tag in tags" :key="tag.tag_id">
            <td>
              <input
                v-model="tag.tag_name"
                @change="updateTag(tag)"
                type="text"
              />
            </td>
            <td>
              <button @click="deleteTag(tag.tag_id)">Sil</button>
            </td>
          </tr>
          <tr v-if="tags.length === 0">
            <td colspan="2">Etiket bulunamadı.</td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    name: "TagManagement",
    data() {
      return {
        tags: [],
        newTagName: "",
      };
    },
    methods: {
      async fetchTags() {
        try {
          const response = await axios.get("/admin/tags");
          this.tags = response.data.data || [];
        } catch (error) {
          console.error("Etiketler alınırken hata oluştu:", error);
        }
      },
      async addTag() {
        try {
          await axios.post("/admin/tags", { tag_name: this.newTagName });
          alert("Yeni etiket eklendi.");
          this.newTagName = "";
          this.fetchTags();
        } catch (error) {
          console.error("Etiket eklenirken hata oluştu:", error);
        }
      },
      async updateTag(tag) {
        try {
          await axios.put(`/admin/tags/${tag.tag_id}`, { tag_name: tag.tag_name });
          alert("Etiket güncellendi.");
        } catch (error) {
          console.error("Etiket güncellenirken hata oluştu:", error);
        }
      },
      async deleteTag(tagId) {
        try {
          await axios.delete(`/admin/tags/${tagId}`);
          alert("Etiket silindi.");
          this.fetchTags();
        } catch (error) {
          console.error("Etiket silinirken hata oluştu:", error);
        }
      },
    },
    mounted() {
      this.fetchTags();
    },
  };
  </script>
  
  <style scoped>
  .tag-management {
    padding: 20px;
  }
  </style>
  