<template>
    <div class="add-question">
      <h3>Yeni Soru Ekle</h3>
      <form @submit.prevent="createQuestion">
        <div>
          <label for="title">Başlık:</label>
          <input v-model="form.title" type="text" id="title" required />
        </div>
  
        <div>
          <label for="details">Detaylar:</label>
          <textarea v-model="form.details" id="details"></textarea>
        </div>
  
        <div>
          <label for="user_id">Kullanıcı ID:</label>
          <input v-model="form.user_id" type="number" id="user_id" required />
        </div>
  
        <div>
          <label for="status">Durum:</label>
          <select v-model="form.status" id="status">
            <option value="Pending">Beklemede</option>
            <option value="Approved">Onaylandı</option>
            <option value="Rejected">Reddedildi</option>
          </select>
        </div>
  
        <div>
          <label for="is_solved">Çözüldü mü?</label>
          <input v-model="form.is_solved" type="checkbox" id="is_solved" />
        </div>
  
        <div>
          <label for="is_deleted">Silindi mi?</label>
          <input v-model="form.is_deleted" type="checkbox" id="is_deleted" />
        </div>
  
        <div>
          <label for="view_count">Görüntüleme Sayısı:</label>
          <input v-model="form.view_count" type="number" id="view_count" />
        </div>
  
        <div>
          <label for="is_important">Önemli mi?</label>
          <input v-model="form.is_important" type="checkbox" id="is_important" />
        </div>
  
        <button type="submit">Soru Ekle</button>
      </form>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    name: "AddQuestionAdmin",
    data() {
      return {
        form: {
          title: "",
          details: "",
          user_id: null,
          status: "Pending",
          is_solved: false,
          is_deleted: false,
          view_count: 0,
          is_important: false,
        },
      };
    },
    methods: {
        async createQuestion() {
          try {
            await axios.post("/admin/forumquestions", this.form);
            alert("Soru başarıyla oluşturuldu!");
            this.$router.push("/forumquestions");
          } catch (error) {
            console.error("Soru oluşturulurken hata oluştu:", error);
            alert("Bir hata oluştu, lütfen tekrar deneyin.");
          }
        },
    },
  };
  </script>
  
  <style scoped>
  .add-question {
    padding: 20px;
  }
  .add-question label {
    display: block;
    margin-bottom: 5px;
  }
  .add-question input,
  .add-question textarea,
  .add-question select {
    width: 100%;
    margin-bottom: 15px;
    padding: 8px;
  }
  .add-question button {
    padding: 10px 20px;
  }
  </style>
  