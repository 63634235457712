<template>
  <div> 
    <div class="button-container">
      <button
        v-for="(item, index) in components"
        :key="index"
        :class="{ active: currentComponent === item.component }"
        @click="currentComponent = item.component"
      >
        {{ item.name }}
      </button>
    </div> 
    <component :is="currentComponent"></component>
  </div>
</template>


<script> 
import StudioList from './studio/StudioList.vue'; 
import StudioManager from './studio/StudioManager.vue'; 
import SubDomains from './subdomains/SubDomains.vue'; 

export default {
  data() {
    return {
      currentComponent: 'StudioList', 
      components: [
        { name: 'Studio List', component: 'StudioList' },
        { name: 'Studio Manager', component: 'StudioManager' }, 
        { name: 'Raklam Siteleri', component: 'SubDomains' }, 
 
      ]
    };
  },

  components: {
    StudioManager,
    StudioList, 
    SubDomains,
  },
  
};
</script>
  
<style scoped>
.button-container {
margin: 1em 0rem;
padding: 0.8rem;
text-align: center;
display: flex; 
flex-wrap: wrap; 
} 

.button-container button { 
border-radius: 0.4rem;
display: inline-block;
padding: 0.4rem 2rem;
min-width: 6rem;
margin: 0.2rem;
border: 0.06rem solid #262626;
background-color: #0b0e0f;
color: #b5b5b5;
cursor: pointer;
display: flex;
align-items: center;
justify-content: center;
} 
.button-container button:hover {
background-color: #000000;  
color: #d9dee0;
} 
.button-container button.active {
background-color: #000000;
color: #63a7b7; 
} 
@media (max-width: 360px) {
.button-container {
  flex-direction: column;
} 
.button-container button {
  width: 90%;
  margin: 0.2em auto; 
}
}
</style>
  