<template>
  <div class="user-mtspaces-manager">
    <h3>Kullanıcı MtSpaces Yönetimi</h3>

    <input
      v-model="searchQuery"
      @input="filterUsers"
      placeholder="Kullanıcı adı veya ID ara"
    />
    <ul v-if="filteredUsers.length > 0" class="user-suggestions">
      <li
        v-for="user in filteredUsers"
        :key="user.user_id"
        @click="selectUser(user)"
      >
        {{ user.user_name }} (ID: {{ user.user_id }})
      </li>
    </ul>

    <div v-if="selectedUser">
      <h4>MtSpaces - {{ selectedUser.user_name }}</h4>
      <table>
        <thead>
          <tr>
            <th>Ad</th>
            <th>Açıklama</th>
            <th>Arşivlendi</th>
            <th>İşlemler</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="space in spaces" :key="space.id">
            <td>
              <input v-model="space.name" @input="autoUpdate(space)" />
            </td>
            <td>
              <input v-model="space.description" @input="autoUpdate(space)" />
            </td>
            <td>
              <input
                type="checkbox"
                :checked="space.is_archived === 1"
                @change="toggleArchive(space)"
              />
            </td>
            <td>
              <button class="delete" @click="confirmDelete(space.id)">Sil</button>
            </td>
          </tr>
        </tbody>
      </table>

      <h4>Yeni MtSpace Ekle</h4>
      <form @submit.prevent="addSpace">
        <input v-model="newSpace.name" placeholder="Ad" required />
        <textarea v-model="newSpace.description" placeholder="Açıklama"></textarea>
        <label>
          <input type="checkbox" v-model="newSpace.is_archived" /> Arşivlendi mi?
        </label>
        <select v-model="newSpace.currency_id">
          <option
            v-for="currency in currencies"
            :key="currency.currency_id"
            :value="currency.currency_id"
          >
            {{ currency.currency_symbol }} - {{ currency.currency_name }}
          </option>
        </select>
        <button type="submit">Ekle</button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      users: [],
      filteredUsers: [],
      searchQuery: "",
      selectedUser: null,
      spaces: [],
      currencies: [],
      newSpace: {
        name: "",
        description: "",
        is_archived: false,
        currency_id: "",
      },
    };
  },
  methods: {
    async fetchUsers() {
      try {
        const response = await axios.get("/admin/mainusers");
        this.users = response.data;
      } catch (error) {
        console.error("Kullanıcılar alınırken hata oluştu:", error);
      }
    },
    async fetchCurrencies() {
      try {
        const response = await axios.get("/auth/currencies");
        this.currencies = response.data;
      } catch (error) {
        console.error("Para birimleri alınırken hata oluştu:", error);
      }
    },
    async fetchUserSpaces(userId) {
      try {
        const response = await axios.get(`/admin/user/${userId}/mt-spaces`);
        this.spaces = response.data.data.map(space => ({
          ...space,
          is_archived: space.is_archived ? 1 : 0, 
        }));
      } catch (error) {
        console.error("MtSpaces alınırken hata oluştu:", error);
      }
    },
    filterUsers() {
      this.filteredUsers = this.users.filter(user =>
        user.user_name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
        user.user_id.toString().includes(this.searchQuery)
      );
    },
    selectUser(user) {
      this.selectedUser = user;
      this.searchQuery = "";
      this.filteredUsers = [];
      this.fetchUserSpaces(user.user_id);
    },
    async addSpace() {
      try {
        const response = await axios.post("/admin/user/mt-spaces", {
          user_id: this.selectedUser.user_id,
          ...this.newSpace,
        });
        this.spaces.push({
          ...response.data.data,
          is_archived: response.data.data.is_archived ? 1 : 0,
        });
        this.newSpace = { name: "", description: "", is_archived: false, currency_id: "" };
      } catch (error) {
        console.error("MtSpace eklenirken hata oluştu:", error);
      }
    },
    async autoUpdate(space) {
      try {
        await axios.put(`/admin/user/mt-spaces/${space.id}`, space);
      } catch (error) {
        console.error("MtSpace güncellenirken hata oluştu:", error);
      }
    },
    async toggleArchive(space) {
      space.is_archived = space.is_archived === 1 ? 0 : 1;
      this.autoUpdate(space);
    },
    async confirmDelete(id) {
      if (confirm("Bu MtSpace kaydını silmek istediğinize emin misiniz?")) {
        this.deleteSpace(id);
      }
    },
    async deleteSpace(id) {
      try {
        await axios.delete(`/admin/user/mt-spaces/${id}`);
        this.spaces = this.spaces.filter(space => space.id !== id);
      } catch (error) {
        console.error("MtSpace silinirken hata oluştu:", error);
      }
    },
  },
  mounted() {
    this.fetchUsers();
    this.fetchCurrencies();
  },
};
</script>

<style scoped>
.user-suggestions {
  list-style: none;
  padding: 0;
}
.user-suggestions li {
  cursor: pointer;
  padding: 5px;
  border-bottom: 1px solid #202020;
}
.user-suggestions li:hover {
  background-color: #000000;
}
</style>
