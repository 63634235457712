<template>
    <div class="faq-management">
      <h3>SSS Yönetimi</h3>
  
      <h4>{{ isEdit ? "SSS Düzenle" : "Yeni SSS Ekle" }}</h4>
      <form @submit.prevent="isEdit ? updateFaq() : createFaq()">
        <textarea v-model="selectedFaq.question" placeholder="Soru" required></textarea>
        <textarea v-model="selectedFaq.answer" placeholder="Cevap" required></textarea>
  
        <input v-model="selectedFaq.category" placeholder="Kategori" />
        <input
          type="number"
          v-model.number="selectedFaq.order_number"
          placeholder="Sıra Numarası"
        />
  
        <button type="submit">{{ isEdit ? "Güncelle" : "Ekle" }}</button>
        <button v-if="isEdit" @click="cancelEdit" type="button">İptal</button>
      </form>
  
      <div class="table-header">
        <h4>SSS Listesi</h4>
        <label>
          <input type="checkbox" v-model="showDeleteButtons" /> Silme Butonlarını Göster
        </label>
      </div>
  
      <table class="faq-table">
        <thead>
          <tr>
            <th>Sıra</th>
            <th>Soru</th>
            <th>Cevap</th>
            <th>Kategori</th>
            <th>İşlemler</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="faq in faqs" :key="faq.faq_id">
            <td>{{ faq.order_number || "—" }}</td>
            <td>{{ faq.question }}</td>
            <td>{{ faq.answer }}</td>
            <td>{{ faq.category || "—" }}</td>
            <td>
              <button @click="editFaq(faq)">Düzenle</button>
              <button
                v-if="showDeleteButtons"
                class="delete-button"
                @click="confirmDelete(faq.faq_id)"
              >
                Sil
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    data() {
      return {
        faqs: [], // SSS kayıtlarını saklar
        selectedFaq: {
          faq_id: null,
          question: "",
          answer: "",
          category: "",
          order_number: null,
        },
        isEdit: false,  
        showDeleteButtons: false, 
      };
    },
    methods: {
      async fetchFaqs() {
        try {
          const response = await axios.get("/promo/faqs");
          this.faqs = response.data.faqs || [];
          console.log("SSS'ler başarıyla alındı:", this.faqs);
        } catch (error) {
          console.error("SSS'ler alınırken hata oluştu:", error);
        }
      },
      async createFaq() {
        try {
          const response = await axios.post("/promo/faqs", this.selectedFaq);
          console.log("SSS başarıyla eklendi:", response.data);
          this.fetchFaqs();
          this.resetForm();
        } catch (error) {
          console.error("SSS eklenirken hata oluştu:", error);
        }
      },
      async updateFaq() {
        try {
          const response = await axios.put(
            `/promo/faqs/${this.selectedFaq.faq_id}`,
            this.selectedFaq
          );
          console.log("SSS başarıyla güncellendi:", response.data);
          this.fetchFaqs();
          this.resetForm();
        } catch (error) {
          console.error("SSS güncellenirken hata oluştu:", error);
        }
      },
      async deleteFaq(faq_id) {
        try {
          const response = await axios.delete(`/promo/faqs/${faq_id}`);
          console.log("SSS başarıyla silindi:", response.data);
          this.fetchFaqs();
        } catch (error) {
          console.error("SSS silinirken hata oluştu:", error);
        }
      },
      editFaq(faq) {
        this.selectedFaq = { ...faq };
        this.isEdit = true;
      },
      confirmDelete(faq_id) {
        if (confirm("Bu SSS kaydını silmek istediğinizden emin misiniz?")) {
          this.deleteFaq(faq_id);
        }
      },
      resetForm() {
        this.selectedFaq = {
          faq_id: null,
          question: "",
          answer: "",
          category: "",
          order_number: null,
        };
        this.isEdit = false;
      },
      cancelEdit() {
        this.resetForm();
      },
    },
    mounted() {
      this.fetchFaqs();  
    },
  };
  </script> 