<template>
  <div class="all-transactions">
    <h3>Tüm MtTransactions</h3>
    <table>
      <thead>
        <tr>
          <th>ID</th>
          <th>Space ID</th>
          <th>Tür</th>
          <th>Kategori ID</th>
          <th>Tutar</th>
          <th>Para Birimi</th>
          <th>Tarih</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="transaction in transactions" :key="transaction.id">
          <td>{{ transaction.id }}</td>
          <td>{{ transaction.space_id }}</td>
          <td>{{ transaction.type }}</td>
          <td>{{ transaction.category_id }}</td>
          <td>{{ transaction.amount }}</td>
          <td>{{ transaction.currency_id }}</td>
          <td>{{ formatDate(transaction.transaction_date) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      transactions: [],
    };
  },
  methods: {
    async fetchTransactions() {
      try {
        const response = await axios.get("/admin/mt-transactions");
        this.transactions = response.data.data;
      } catch (error) {
        console.error("MtTransactions alınırken hata oluştu:", error);
      }
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString();
    },
  },
  mounted() {
    this.fetchTransactions();
  },
};
</script>
