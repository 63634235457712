<template>
  <div class="noah-master-management">
    <h3>Noah Master Yönetimi</h3>
 
    <h4>{{ isEdit ? "Noah Master Düzenle" : "Yeni Noah Master Ekle" }}</h4>
    <form @submit.prevent="isEdit ? updateNoahMaster() : createNoahMaster()">
      <select v-model="selectedMaster.level_id" required>
        <option disabled value="">Master Seviyesi Seçin</option>
        <option v-for="level in masterLevels" :key="level.level_id" :value="level.level_id">
          {{ level.title }}
        </option>
      </select>

      <input v-model="selectedMaster.name" placeholder="İsim" required />
      <input v-model="selectedMaster.phone" placeholder="Telefon" />
      <input v-model="selectedMaster.e_mail" placeholder="E-posta" />
      <input v-model="selectedMaster.instagram" placeholder="Instagram Kullanıcı Adı" />
      <input v-model="selectedMaster.country" placeholder="Ülke" />
      <input v-model="selectedMaster.city" placeholder="Şehir" />
      <textarea v-model="selectedMaster.bio" placeholder="Biyografi" rows="4"></textarea>
      <input v-model="selectedMaster.sira_no" placeholder="Sıra Numarası" />

      <div>
        <label for="image">Fotoğraf:</label>
        <input class="upload-button" type="file" id="image" @change="handleFileUpload($event)" />
        <img v-if="preview.image" :src="preview.image" alt="Master Fotoğrafı" class="image-preview" />
      </div>

      <button type="submit">{{ isEdit ? "Güncelle" : "Ekle" }}</button>
      <button v-if="isEdit" @click="cancelEdit" type="button">İptal</button>
    </form>
 
    <div class="table-header">
      <h4>Noah Masterlar</h4>
      <label>
        <input type="checkbox" v-model="showDeleteButtons" />
        Silme Butonlarını Göster
      </label>
    </div>

    <table class="noah-master-table">
      <thead>
        <tr>
          <th>Seviye</th>
          <th>İsim</th>
          <th>Ülke</th>
          <th>Şehir</th>
          <th>Telefon</th>
          <th>Sıra No</th>
          <th>Fotoğraf</th>
          <th>İşlemler</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="master in noahMasters" :key="master.master_id">
   
          <td>
            <div v-if="editableField.master_id === master.master_id && editableField.field === 'level'">
              <select v-model="editableValue" @change="updateField(master.master_id, 'level')">
                <option v-for="level in masterLevels" :key="level.level_id" :value="level.title">{{ level.title }}</option>
              </select>
            </div>
            <div v-else @click="makeFieldEditable(master.master_id, 'level', master.level)">
              {{ master.level }}
            </div>
          </td>
 
          <td>
            <div v-if="editableField.master_id === master.master_id && editableField.field === 'name'">
              <input
                type="text"
                v-model="editableValue"
                @blur="updateField(master.master_id, 'name')"
                @keydown.enter.prevent="updateField(master.master_id, 'name')"
              />
            </div>
            <div v-else @click="makeFieldEditable(master.master_id, 'name', master.name)">
              {{ master.name }}
            </div>
          </td>
 
          <td>
            <div v-if="editableField.master_id === master.master_id && editableField.field === 'country'">
              <input
                type="text"
                v-model="editableValue"
                @blur="updateField(master.master_id, 'country')"
                @keydown.enter.prevent="updateField(master.master_id, 'country')"
              />
            </div>
            <div v-else @click="makeFieldEditable(master.master_id, 'country', master.country)">
              {{ master.country || "Yok" }}
            </div>
          </td>
 
          <td>
            <div v-if="editableField.master_id === master.master_id && editableField.field === 'city'">
              <input
                type="text"
                v-model="editableValue"
                @blur="updateField(master.master_id, 'city')"
                @keydown.enter.prevent="updateField(master.master_id, 'city')"
              />
            </div>
            <div v-else @click="makeFieldEditable(master.master_id, 'city', master.city)">
              {{ master.city || "Yok" }}
            </div>
          </td>
 
          <td>
            <div v-if="editableField.master_id === master.master_id && editableField.field === 'phone'">
              <input
                type="text"
                v-model="editableValue"
                @blur="updateField(master.master_id, 'phone')"
                @keydown.enter.prevent="updateField(master.master_id, 'phone')"
              />
            </div>
            <div v-else @click="makeFieldEditable(master.master_id, 'phone', master.phone)">
              {{ master.phone || "Yok" }}
            </div>
          </td>
 
          <td>
            <div v-if="editableField.master_id === master.master_id && editableField.field === 'sira_no'">
              <input
                type="number"
                v-model="editableValue"
                @blur="updateField(master.master_id, 'sira_no')"
                @keydown.enter.prevent="updateField(master.master_id, 'sira_no')"
              />
            </div>
            <div v-else @click="makeFieldEditable(master.master_id, 'sira_no', master.sira_no)">
              {{ master.sira_no || "Yok" }}
            </div>
          </td>
 
          <td>
            <img
              v-if="master.image_link"
              :src="master.image_link"
              alt="Master Fotoğrafı"
              class="image-preview"
            />
            <span v-else>Yok</span>
          </td>
 
          <td>
            <button @click="editNoahMaster(master)">Düzenle</button>
            <button
              v-if="showDeleteButtons"
              class="delete-button"
              @click="confirmDelete(master.master_id)"
            >
              Sil
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      noahMasters: [],
      masterLevels: [],
      editableField: { master_id: null, field: null },
      editableValue: null,
      selectedMaster: {
        master_id: null,
        level_id: "",
        name: "",
        phone: "",
        e_mail: "",
        instagram: "",
        country: "",
        city: "",
        bio: "",
        sira_no: "",
        image: null,
      },
      preview: {
        image: null,
      },
      isEdit: false,
      showDeleteButtons: false,
    };
  },
  methods: {
    async fetchNoahMasters() {
      try {
        const response = await axios.get("/promo/noah-masters");
        this.noahMasters = response.data.masters || [];
      } catch (error) {
        console.error("Noah masterlar alınırken hata oluştu:", error);
      }
    },
    async fetchMasterLevels() {
      try {
        const response = await axios.get("/promo/master-levels");
        this.masterLevels = response.data.masterLevels || [];
      } catch (error) {
        console.error("Master seviyeleri alınırken hata oluştu:", error);
      }
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        this.preview.image = e.target.result;
        this.selectedMaster.image = file;
      };
      reader.readAsDataURL(file);
    },
    async createNoahMaster() {
      try {
        const formData = new FormData();
        Object.entries(this.selectedMaster).forEach(([key, value]) => {
          formData.append(key, value);
        });

        await axios.post("/promo/noah-master", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        this.fetchNoahMasters();
        this.resetForm();
      } catch (error) {
        console.error("Noah master eklenirken hata oluştu:", error);
      }
    },
    async updateNoahMaster() {
      try {
        const formData = new FormData();
        Object.entries(this.selectedMaster).forEach(([key, value]) => {
          formData.append(key, value);
        });

        await axios.put(`/promo/noah-master/${this.selectedMaster.master_id}`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        this.fetchNoahMasters();
        this.resetForm();
      } catch (error) {
        console.error("Noah master güncellenirken hata oluştu:", error);
      }
    },
    makeFieldEditable(master_id, field, value) {
      this.editableField = { master_id, field };
      this.editableValue = value;
    },
    async updateField(master_id, field) {
      try {
        const updatedMaster = this.noahMasters.find(
          (master) => master.master_id === master_id
        );
        if (!updatedMaster) return;

        const payload = { [field]: this.editableValue };
        await axios.put(`/promo/noah-master/${master_id}`, payload);

        updatedMaster[field] = this.editableValue;
        this.editableField = { master_id: null, field: null };
        this.editableValue = null;
      } catch (error) {
        console.error(`${field} alanı güncellenirken hata oluştu:`, error);
      }
    },
    editNoahMaster(master) {
      this.selectedMaster = { ...master, image: null };
      this.preview.image = master.image_link;
      this.isEdit = true;
    },
    async deleteNoahMaster(id) {
      try {
        await axios.delete(`/promo/noah-master/${id}`);
        this.fetchNoahMasters();
      } catch (error) {
        console.error("Noah master silinirken hata oluştu:", error);
      }
    },
    confirmDelete(id) {
      if (confirm("Bu noah masterını silmek istediğinizden emin misiniz?")) {
        this.deleteNoahMaster(id);
      }
    },
    resetForm() {
      this.selectedMaster = {
        master_id: null,
        level_id: "",
        name: "",
        phone: "",
        e_mail: "",
        instagram: "",
        country: "",
        city: "",
        bio: "",
        sira_no: "",
        image: null,
      };
      this.preview.image = null;
      this.isEdit = false;
    },
    cancelEdit() {
      this.resetForm();
    },
  },
  mounted() {
    this.fetchNoahMasters();
    this.fetchMasterLevels();
  },
};
</script>

<style scoped>
.inline-input,
select {
  width: 100%; 
  border-radius: 4px;
  padding: 4px;
  font-size: 1em;
}
.image-preview {
  max-width: 100px;
  max-height: 100px;
  border-radius: 5px;
}
</style>
