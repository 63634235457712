<template>
  <div class="mt-spaces-list">
    <h3>MtSpaces Kayıtları</h3>

    <table>
      <thead>
        <tr>
          <th>ID</th>
          <th>Kullanıcı ID</th>
          <th>Para Birimi ID</th>
          <th>Ad</th>
          <th>Açıklama</th>
          <th>Arşivlendi</th>
          <th>Oluşturulma Tarihi</th>
          <th>Güncellenme Tarihi</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="space in spaces" :key="space.id">
          <td>{{ space.id }}</td>
          <td>{{ space.user_id }}</td>
          <td>{{ space.currency_id }}</td>
          <td>{{ space.name }}</td>
          <td>{{ space.description || "Yok" }}</td>
          <td>{{ space.is_archived ? "Evet" : "Hayır" }}</td>
          <td>{{ formatDate(space.created_at) }}</td>
          <td>{{ formatDate(space.updated_at) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      spaces: [],
    };
  },
  methods: {
    async fetchSpaces() {
      try {
        const response = await axios.get("/admin/mt-spaces");
        this.spaces = response.data.data;
      } catch (error) {
        console.error("MtSpaces alınırken hata oluştu:", error);
      }
    },
    formatDate(date) {
      if (!date) return "Bilinmiyor";
      return new Date(date).toLocaleString();
    },
  },
  mounted() {
    this.fetchSpaces();
  },
};
</script>

<style scoped> 
</style>
