<template>
    <div class="question-management">
      <h3>Soru Yönetimi</h3>
      <div v-if="question">
        <h4>{{ question.title }}</h4>
        <p>{{ question.details }}</p>
        <div>
          <button @click="markAsSolved">Çözüldü Olarak İşaretle</button>
          <button @click="deleteQuestion">Soruyu Sil</button>
          <select v-model="question.status" @change="updateStatus">
            <option value="Pending">Beklemede</option>
            <option value="Active">Aktif</option>
            <option value="Solved">Çözüldü</option>
            <option value="Deleted">Silindi</option>
          </select>
        </div>
      </div>
      <div v-else>
        <p>Soru yükleniyor...</p>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    name: "QuestionManagement",
    data() {
      return {
        question: null,
      };
    },
    methods: {
      async fetchQuestion() {
        const id = this.$route.params.id;
        try {
          const response = await axios.get(`/admin/questions/${id}`);
          this.question = response.data.data;
        } catch (error) {
          console.error("Soru yüklenirken hata oluştu:", error);
        }
      },
      async markAsSolved() {
        try {
          await axios.put(`/admin/questions/${this.question.question_id}/mark-solved`);
          alert("Soru çözüldü olarak işaretlendi.");
          this.fetchQuestion();
        } catch (error) {
          console.error("Soru çözüldü olarak işaretlenemedi:", error);
        }
      },
      async deleteQuestion() {
        try {
          await axios.delete(`/admin/questions/${this.question.question_id}`);
          alert("Soru silindi.");
          this.$router.push("/questions");
        } catch (error) {
          console.error("Soru silinemedi:", error);
        }
      },
      async updateStatus() {
        try {
          await axios.put(`/admin/questions/${this.question.question_id}`, {
            status: this.question.status,
          });
          alert("Soru durumu güncellendi.");
        } catch (error) {
          console.error("Soru durumu güncellenemedi:", error);
        }
      },
    },
    mounted() {
      this.fetchQuestion();
    },
  };
  </script>
  
  <style scoped>
  .question-management {
    padding: 20px;
  }
  </style>
  