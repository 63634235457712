<template>
    <div class="message-manager">
      <h3>Sohbet Mesajları Yönetimi</h3>
  
      <select v-model="selectedRoomId" @change="fetchMessages">
        <option disabled value="">Bir sohbet odası seçin</option>
        <option v-for="room in chatRooms" :key="room.id" :value="room.id">
          {{ room.name }}
        </option>
      </select>
  
      <table v-if="messages.length > 0">
        <thead>
          <tr>
            <th>ID</th>
            <th>Mesaj</th>
            <th>Ek Dosya</th>
            <th>Oluşturulma Tarihi</th>
            <th>İşlemler</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="message in messages" :key="message.id">
            <td>{{ message.id }}</td>
            <td>
              <textarea v-model="message.message" rows="2" />
            </td>
            <td>
              <a v-if="message.attachment_url" :href="message.attachment_url" target="_blank">Dosya</a>
            </td>
            <td>{{ formatDate(message.created_at) }}</td>
            <td>
              <button @click="updateMessage(message)">Güncelle</button>
              <button class="delete" @click="deleteMessage(message.id)">Sil</button>
            </td>
          </tr>
        </tbody>
      </table>
  
      <p v-else>Sohbet odası için mesaj bulunamadı.</p>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    data() {
      return {
        chatRooms: [],
        selectedRoomId: "",
        messages: [],
      };
    },
    methods: {
      async fetchChatRooms() {
        try {
          const response = await axios.get("/promo/chatrooms");
          this.chatRooms = response.data.data;
        } catch (error) {
          console.error("Sohbet odaları alınırken hata oluştu:", error);
        }
      },
      async fetchMessages() {
        if (!this.selectedRoomId) return;
        try {
          const response = await axios.get(`/promo/chatrooms/${this.selectedRoomId}/messages`);
          this.messages = response.data.data;
        } catch (error) {
          console.error("Mesajlar alınırken hata oluştu:", error);
        }
      },
      async updateMessage(message) {
        try {
          await axios.put(`/promo/messages/${message.id}`, { message: message.message });
          alert("Mesaj başarıyla güncellendi.");
        } catch (error) {
          console.error("Mesaj güncellenirken hata oluştu:", error);
        }
      },
      async deleteMessage(id) {
        if (confirm("Bu mesajı silmek istediğinizden emin misiniz?")) {
          try {
            await axios.delete(`/promo/messages/${id}`);
            this.fetchMessages();
          } catch (error) {
            console.error("Mesaj silinirken hata oluştu:", error);
          }
        }
      },
      formatDate(date) {
        return new Date(date).toLocaleString();
      },
    },
    mounted() {
      this.fetchChatRooms();
    },
  };
  </script>
  