<template>
    <div class="question-list">
      <h3>Sorular</h3>
   
      <div class="filters">
        <input
          v-model="searchQuery"
          type="text"
          placeholder="Sorularda ara..."
        />
        <select v-model="statusFilter">
          <option value="">Tümü</option>
          <option value="Pending">Beklemede</option>
          <option value="Active">Aktif</option>
          <option value="Solved">Çözüldü</option>
          <option value="Deleted">Silindi</option>
        </select>
        <button @click="fetchQuestions">Uygula</button>
      </div>
   
      <table v-if="!selectedQuestion">
        <thead>
          <tr>
            <th>Başlık</th>
            <th>Durum</th>
            <th>İşlemler</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="question in filteredQuestions" :key="question.question_id">
            <td>{{ question.title }}</td>
            <td>{{ question.status }}</td>
            <td>
              <button @click="viewDetails(question.question_id)">Detaylar</button>
            </td>
          </tr>
          <tr v-if="filteredQuestions.length === 0">
            <td colspan="3">Soru bulunamadı.</td>
          </tr>
        </tbody>
      </table>

      <div v-else class="question-details">
        <h4>{{ selectedQuestion.title }}</h4>
        <p>{{ selectedQuestion.details }}</p>
        <p><strong>Durum:</strong> {{ selectedQuestion.status }}</p>
        <button @click="closeDetails">Geri</button>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    name: "QuestionList",
    data() {
      return {
        questions: [], 
        searchQuery: "",
        statusFilter: "",
        selectedQuestion: null,
      };
    },
    computed: {
      filteredQuestions() {
        if (!this.questions || !Array.isArray(this.questions)) {
          return []; 
        }
        return this.questions.filter((question) => {
          return (
            (this.statusFilter === "" || question.status === this.statusFilter) &&
            (this.searchQuery === "" ||
              question.title.toLowerCase().includes(this.searchQuery.toLowerCase()))
          );
        });
      },
    },
    methods: {
      async fetchQuestions() {
        try {
          const response = await axios.get("/admin/forumquestions", {
            params: {
              status: this.statusFilter,
              search: this.searchQuery,
            },
          });
          this.questions = response.data.data || [];  
        } catch (error) {
          console.error("Sorular alınırken bir hata oluştu:", error);
        }
      },
      async viewDetails(id) {
        try {
          const response = await axios.get(`/admin/forumquestions/${id}`);
          this.selectedQuestion = response.data.data;
        } catch (error) {
          console.error("Soru detayları alınırken bir hata oluştu:", error);
        }
      },
      closeDetails() {
        this.selectedQuestion = null;
      },
    },
    mounted() {
      this.fetchQuestions();
    },
  };
  </script>
  
  <style scoped>
  .question-list {
    padding: 20px;
  }
  
  .filters {
    margin-bottom: 20px;
  }
  
  .filters input,
  .filters select {
    margin-right: 10px;
  }

  .question-details {
    padding: 20px;
    border: 1px solid #333333;
    background: #000000;
  }
  </style>
