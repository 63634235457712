<template>
    <div class="user-experts-manager">
      <h3>Kullanıcı Uzman Yönetimi</h3>
      <input
        v-model="searchQuery"
        @input="filterUsers"
        placeholder="Kullanıcı adı veya ID ara"
      />
      <ul v-if="filteredUsers.length > 0" class="user-suggestions">
        <li
          v-for="user in filteredUsers"
          :key="user.user_id"
          @click="selectUser(user)"
        >
          {{ user.user_name }} (ID: {{ user.user_id }})
        </li>
      </ul>
  
      <div v-if="selectedUser">
        <h4>Uzmanlar - {{ selectedUser.user_name }}</h4>
        <table class="experts-table">
          <thead>
            <tr>
              <th>Adı</th>
              <th>Email</th>
              <th>Telefon</th>
              <th>İşlemler</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="expert in experts" :key="expert.id">
              <td>
                <input v-model="expert.name" />
              </td>
              <td>
                <input v-model="expert.email" />
              </td>
              <td>
                <input v-model="expert.phone" />
              </td>
              <td>
                <button @click="updateExpert(expert)">Güncelle</button>
                <button @click="deleteExpert(expert.id)">Sil</button>
              </td>
            </tr>
          </tbody>
        </table>
  
        <h4>Yeni Uzman Ekle</h4>
        <form @submit.prevent="addExpert">
          <label>Adı</label>
          <input v-model="newExpert.name" placeholder="Adı" />
          <label>Email</label>
          <input v-model="newExpert.email" placeholder="Email" />
          <label>Telefon</label>
          <input v-model="newExpert.phone" placeholder="Telefon" />
          <button type="submit">Uzman Ekle</button>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    data() {
      return {
        users: [],
        filteredUsers: [],
        searchQuery: "",
        selectedUser: null,
        experts: [],
        newExpert: {
          name: "",
          email: "",
          phone: "",
        },
      };
    },
    methods: {
      async fetchUsers() {
        try {
          const response = await axios.get("/admin/mainusers");
          this.users = response.data;
        } catch (error) {
          console.error("Kullanıcılar alınırken hata oluştu:", error);
        }
      },
      async fetchExperts(userId) {
        try {
          const response = await axios.get(`/admin/cl/experts/user/${userId}`);
          this.experts = response.data;
        } catch (error) {
          console.error("Uzmanlar alınırken hata oluştu:", error);
        }
      },
      async addExpert() {
        try {
          const expertData = {
            user_id: this.selectedUser.user_id,
            name: this.newExpert.name,
            email: this.newExpert.email,
            phone: this.newExpert.phone,
          };
          await axios.post("/admin/cl/experts", expertData);
          alert("Uzman başarıyla eklendi.");
          this.fetchExperts(this.selectedUser.user_id);
        } catch (error) {
          console.error("Uzman eklenirken hata oluştu:", error);
        }
      },
      async updateExpert(expert) {
        try {
          await axios.put(`/admin/cl/experts/${expert.id}`, {
            name: expert.name,
            email: expert.email,
            phone: expert.phone,
          });
          alert("Uzman başarıyla güncellendi.");
        } catch (error) {
          console.error("Uzman güncellenirken hata oluştu:", error);
        }
      },
      async deleteExpert(expertId) {
        if (confirm("Bu uzmanı silmek istediğinize emin misiniz?")) {
          try {
            await axios.delete(`/admin/cl/experts/${expertId}`);
            this.experts = this.experts.filter(expert => expert.id !== expertId);
            alert("Uzman başarıyla silindi.");
          } catch (error) {
            console.error("Uzman silinirken hata oluştu:", error);
          }
        }
      },
      filterUsers() {
        if (this.searchQuery.trim() === "") {
          this.filteredUsers = [];
          return;
        }
        this.filteredUsers = this.users.filter(user => {
          const userName = user.user_name?.toLowerCase() || "";
          const userId = user.user_id?.toString() || "";
          return (
            userName.includes(this.searchQuery.toLowerCase()) ||
            userId.includes(this.searchQuery)
          );
        });
      },
      async selectUser(user) {
        this.selectedUser = user;
        this.searchQuery = "";
        this.filteredUsers = [];
        await this.fetchExperts(user.user_id);
      },
    },
    mounted() {
      this.fetchUsers();
    },
  };
  </script>
  
  <style scoped>
  .user-suggestions {
    list-style: none;
    padding: 0;
  }
  
  .user-suggestions li {
    cursor: pointer;
    padding: 5px;
    border-bottom: 1px solid #222222;
  }
  
  .user-suggestions li:hover {
    background-color: #000000;
  }
   
   
  </style>
  