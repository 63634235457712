<template>
    <div class="user-services-manager">
      <h3>Kullanıcı Hizmet Yönetimi</h3>
      <input
        v-model="searchQuery"
        @input="filterUsers"
        placeholder="Kullanıcı adı veya ID ara"
      />
      <ul v-if="filteredUsers.length > 0" class="user-suggestions">
        <li
          v-for="user in filteredUsers"
          :key="user.user_id"
          @click="selectUser(user)"
        >
          {{ user.user_name }} (ID: {{ user.user_id }})
        </li>
      </ul>
  
      <div v-if="selectedUser">
        <h4>Hizmetler - {{ selectedUser.user_name }}</h4>
        <table class="services-table">
          <thead>
            <tr>
              <th>Hizmet Adı</th>
              <th>Açıklama</th>
              <th>Süre</th>
              <th>Fiyat</th>
              <th>İşlemler</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="service in services" :key="service.id">
              <td>
                <input v-model="service.name" />
              </td>
              <td>
                <input v-model="service.description" />
              </td>
              <td>
                <input v-model.number="service.duration" type="number" />
              </td>
              <td>
                <input v-model.number="service.price" type="number" />
              </td>
              <td>
                <button @click="updateService(service)">Güncelle</button>
                <button class="delete" @click="deleteService(service.id)">Sil</button>
              </td>
            </tr>
          </tbody>
        </table>
  
        <h4>Yeni Hizmet Ekle</h4>
        <form @submit.prevent="addService">
          <label>Varsayılan Hizmet</label>
          <select v-model="newService.defaultServiceId" @change="populateDefaultService">
            <option value="">Seç</option>
            <option
              v-for="service in defaultServices"
              :key="service.id"
              :value="service.id"
            >
              {{ service.name }}
            </option>
          </select>
          <label>Hizmet Adı</label>
          <input v-model="newService.name" placeholder="Hizmet Adı" />
          <label>Açıklama</label>
          <textarea v-model="newService.description" placeholder="Hizmet Açıklaması"></textarea>
          <label>Süre</label>
          <input v-model.number="newService.duration" type="number" placeholder="Süre (dk)" />
          <label>Fiyat</label>
          <input v-model.number="newService.price" type="number" placeholder="Fiyat" />
          <label>Para Birimi</label>
          <select v-model="newService.currencyId">
            <option
              v-for="currency in currencies"
              :key="currency.currency_id"
              :value="currency.currency_id"
            >
              {{ currency.currency_symbol }} - {{ currency.currency_name }}
            </option>
          </select>
          <button type="submit">Hizmet Ekle</button>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    data() {
      return {
        users: [],
        filteredUsers: [],
        searchQuery: "",
        selectedUser: null,
        services: [],
        defaultServices: [],
        currencies: [],
        newService: {
          defaultServiceId: "",
          name: "",
          description: "",
          duration: "",
          price: "",
          currencyId: "",
        },
      };
    },
    methods: {
     async updateService(service) {
        try {
          await axios.put(`/admin/cl/services/${service.id}`, {
            name: service.name,
            description: service.description,
            duration: service.duration,
            price: service.price,
            currency_id: service.currency_id,
          });
          alert("Hizmet başarıyla güncellendi.");
        } catch (error) {
          console.error("Hizmet güncellenirken hata oluştu:", error);
        }
      },
      async deleteService(serviceId) {
        if (confirm("Bu hizmeti silmek istediğinize emin misiniz?")) {
          try {
            await axios.delete(`/admin/cl/services/${serviceId}`);
            this.services = this.services.filter(service => service.id !== serviceId);
            alert("Hizmet başarıyla silindi.");
          } catch (error) {
            console.error("Hizmet silinirken hata oluştu:", error);
          }
        }
      }, 
      async fetchUsers() {
        try {
          const response = await axios.get("/admin/mainusers");
          this.users = response.data;
        } catch (error) {
          console.error("Kullanıcılar alınırken hata oluştu:", error);
        }
      },
      async fetchDefaultServices() {
        try {
          const response = await axios.get("/admin/cl/defaultservices");
          this.defaultServices = response.data;
        } catch (error) {
          console.error("Varsayılan hizmetler alınırken hata oluştu:", error);
        }
      },
      async fetchCurrencies() {
        try {
          const response = await axios.get("/auth/currencies");
          this.currencies = response.data;
          this.newService.currencyId = this.currencies[0]?.currency_id || "";
        } catch (error) {
          console.error("Para birimleri alınırken hata oluştu:", error);
        }
      },
      filterUsers() {
        if (this.searchQuery.trim() === "") {
          this.filteredUsers = [];
          return;
        }
        this.filteredUsers = this.users.filter(user => {
          const userName = user.user_name?.toLowerCase() || "";
          const userId = user.user_id?.toString() || "";
          return (
            userName.includes(this.searchQuery.toLowerCase()) ||
            userId.includes(this.searchQuery)
          );
        });
      },
      async selectUser(user) {
        this.selectedUser = user;
        this.searchQuery = "";
        this.filteredUsers = [];
        await this.fetchUserServices(user.user_id);
      },
      async fetchUserServices(userId) {
        try {
          const response = await axios.get(`/admin/cl/services/user/${userId}`);
          this.services = response.data;
        } catch (error) {
          console.error("Hizmetler alınırken hata oluştu:", error);
        }
      },
      populateDefaultService() {
        const selectedService = this.defaultServices.find(
          service => service.id === this.newService.defaultServiceId
        );
        if (selectedService) {
          this.newService.name = selectedService.name;
          this.newService.description = selectedService.description;
          this.newService.duration = selectedService.duration;
          this.newService.price = selectedService.price;
          this.newService.currencyId = selectedService.currency_id;
        } else {
          this.newService.name = "";
          this.newService.description = "";
          this.newService.duration = "";
          this.newService.price = "";
          this.newService.currencyId = this.currencies[0]?.currency_id || "";
        }
      },
      async addService() {
        try {
          const serviceData = {
            user_id: this.selectedUser.user_id,
            name: this.newService.name,
            description: this.newService.description,
            duration: this.newService.duration,
            price: this.newService.price,
            currency_id: this.newService.currencyId,
          };
          await axios.post("/admin/cl/servicesforuser", serviceData);
          alert("Hizmet başarıyla eklendi.");
          this.fetchUserServices(this.selectedUser.user_id);
        } catch (error) {
          console.error("Hizmet eklenirken hata oluştu:", error);
        }
      },
    },
    mounted() {
      this.fetchUsers();
      this.fetchDefaultServices();
      this.fetchCurrencies();
    },
  };
  </script>
  
  <style scoped>
  .user-suggestions {
    list-style: none;
    padding: 0;
  }
  
  .user-suggestions li {
    cursor: pointer;
    padding: 5px;
    border-bottom: 1px solid #242424;
  }
  
  .user-suggestions li:hover {
    background-color: #000000;
  }
  </style>
  