<template>
    <div class="room-user-manager">
      <h3>Sohbet Odası Kullanıcı Yönetimi</h3>
  
      <select v-model="selectedRoomId" @change="fetchUsers">
        <option disabled value="">Bir sohbet odası seçin</option>
        <option v-for="room in chatRooms" :key="room.id" :value="room.id">
          {{ room.name }}
        </option>
      </select>
  
      <table v-if="users.length > 0">
        <thead>
          <tr>
            <th>Kullanıcı ID</th>
            <th>Online Durumu</th>
            <th>İşlemler</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in users" :key="user.id">
            <td>{{ user.user_id }}</td>
            <td>
              <input type="checkbox" v-model="user.is_online" @change="updateUser(user)" />
            </td>
            <td>
              <button class="delete" @click="deleteUser(user.id)">Kullanıcıyı Çıkar</button>
            </td>
          </tr>
        </tbody>
      </table>
  
      <p v-else>Sohbet odası için kullanıcı bulunamadı.</p>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    data() {
      return {
        chatRooms: [],
        selectedRoomId: "",
        users: [],
      };
    },
    methods: {
      async fetchChatRooms() {
        try {
          const response = await axios.get("/promo/chatrooms");
          this.chatRooms = response.data.data;
        } catch (error) {
          console.error("Sohbet odaları alınırken hata oluştu:", error);
        }
      },
      async fetchUsers() {
        if (!this.selectedRoomId) return;
        try {
          const response = await axios.get(`/promo/chatrooms/${this.selectedRoomId}/users`);
          this.users = response.data.data;
        } catch (error) {
          console.error("Kullanıcılar alınırken hata oluştu:", error);
        }
      },
      async updateUser(user) {
        try {
          await axios.put(`/promo/chatrooms/users/${user.id}`, { is_online: user.is_online });
          alert("Kullanıcı durumu başarıyla güncellendi.");
        } catch (error) {
          console.error("Kullanıcı güncellenirken hata oluştu:", error);
        }
      },
      async deleteUser(id) {
        if (confirm("Bu kullanıcıyı çıkarmak istediğinizden emin misiniz?")) {
          try {
            await axios.delete(`/promo/chatrooms/users/${id}`);
            this.fetchUsers();
          } catch (error) {
            console.error("Kullanıcı çıkarılırken hata oluştu:", error);
          }
        }
      },
    },
    mounted() {
      this.fetchChatRooms();
    },
  };
  </script>
  