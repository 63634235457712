<template>
    <div class="answer-management">
      <h3>Yanıt Yönetimi</h3>
      <div v-if="answer">
        <p>{{ answer.content }}</p>
        <textarea v-model="answer.content" @change="updateAnswer"></textarea>
        <div>
          <button @click="approveAnswer">Onayla</button>
          <button @click="deleteAnswer">Sil</button>
        </div>
      </div>
      <div v-else>
        <p>Yanıt yükleniyor...</p>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    name: "AnswerManagement",
    data() {
      return {
        answer: null,
      };
    },
    methods: {
      async fetchAnswer() {
        const id = this.$route.params.id;
        try {
          const response = await axios.get(`/admin/answers/${id}`);
          this.answer = response.data.data;
        } catch (error) {
          console.error("Yanıt alınırken hata oluştu:", error);
        }
      },
      async updateAnswer() {
        try {
          await axios.put(`/admin/answers/${this.answer.answer_id}`, {
            content: this.answer.content,
          });
          alert("Yanıt güncellendi.");
        } catch (error) {
          console.error("Yanıt güncellenemedi:", error);
        }
      },
      async approveAnswer() {
        try {
          await axios.put(`/admin/answers/${this.answer.answer_id}/approve`);
          alert("Yanıt onaylandı.");
          this.fetchAnswer();
        } catch (error) {
          console.error("Yanıt onaylanamadı:", error);
        }
      },
      async deleteAnswer() {
        try {
          await axios.delete(`/admin/answers/${this.answer.answer_id}`);
          alert("Yanıt silindi.");
          this.$router.push("/questions");
        } catch (error) {
          console.error("Yanıt silinemedi:", error);
        }
      },
    },
    mounted() {
      this.fetchAnswer();
    },
  };
  </script>
  
  <style scoped>
  .answer-management {
    padding: 20px;
  }
  </style>
  