<template>
  <div class="user-appointments-manager">
    <h3>Kullanıcı Randevu Yönetimi</h3>
    <!-- Kullanıcı arama -->
    <input
      v-model="searchQuery"
      @input="filterUsers"
      placeholder="Kullanıcı adı veya ID ara"
    />
    <ul v-if="filteredUsers.length > 0" class="user-suggestions">
      <li
        v-for="user in filteredUsers"
        :key="user.user_id"
        @click="selectUser(user)"
      >
        {{ user.user_name }} (ID: {{ user.user_id }})
      </li>
    </ul>

    <!-- Seçilen kullanıcıya ait veriler -->
    <div v-if="selectedUser">
      <h4>Randevular - {{ selectedUser.user_name }}</h4>
      <table class="appointments-table">
        <thead>
          <tr>
            <th>Başlangıç</th>
            <th>Bitiş</th>
            <th>Durum</th>
            <th>Notlar</th>
            <th>Fiyat</th>
            <th>İşlemler</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="appointment in appointments" :key="appointment.id">
            <td><input v-model="appointment.start_time" type="datetime-local" /></td>
            <td><input v-model="appointment.end_time" type="datetime-local" /></td>
            <td>
              <select v-model="appointment.status">
                <option value="pending">Beklemede</option>
                <option value="confirmed">Onaylandı</option>
                <option value="completed">Tamamlandı</option>
                <option value="cancelled">İptal Edildi</option>
              </select>
            </td>
            <td><input v-model="appointment.notes" /></td>
            <td><input v-model.number="appointment.custom_price" type="number" /></td>
            <td>
              <button @click="updateAppointment(appointment)">Güncelle</button>
              <button @click="deleteAppointment(appointment.id)">Sil</button>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- Yeni Randevu Ekleme Formu -->
      <h4>Yeni Randevu Ekle</h4>
      <form @submit.prevent="addAppointment">
        <label>Müşteri</label>
        <select v-model="newAppointment.customer_id">
          <option v-for="customer in customers" :key="customer.id" :value="customer.id">
            {{ customer.name }} (ID: {{ customer.id }})
          </option>
        </select>

        <label>Uzman</label>
        <select v-model="newAppointment.expert_id">
          <option v-for="expert in experts" :key="expert.id" :value="expert.id">
            {{ expert.name }} (ID: {{ expert.id }})
          </option>
        </select>

        <label>Hizmetler</label>
        <div v-for="service in services" :key="service.id">
          <input 
            type="checkbox" 
            :value="service.id" 
            v-model="selectedServices" 
            @change="recalculatePrice"
          />
          {{ service.name }} (₺{{ service.price }})
        </div>

        <label>Başlangıç Zamanı</label>
        <input v-model="newAppointment.start_time" type="datetime-local" required />

        <label>Bitiş Zamanı</label>
        <input v-model="newAppointment.end_time" type="datetime-local" required />

        <label>Parabirimi</label>
        <select v-model="newAppointment.currency_id">
          <option v-for="currency in currencies" :key="currency.currency_id" :value="currency.currency_id">
            {{ currency.currency_symbol }}
          </option>
        </select>

        <label>Fiyat</label>
        <!-- Bu alan otomatik hesaplanıyor ancak kullanıcı isterse editleyebilir -->
        <input v-model.number="newAppointment.custom_price" type="number" placeholder="Fiyat" />

        <label>Notlar</label>
        <textarea v-model="newAppointment.notes" placeholder="Notlar"></textarea>

        <button type="submit">Randevu Ekle</button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      users: [],
      filteredUsers: [],
      searchQuery: "",
      selectedUser: null,
      appointments: [],
      customers: [],
      experts: [],
      services: [],
      currencies: [],
      selectedServices: [],

      newAppointment: {
        customer_id: "",
        expert_id: "",
        start_time: "",
        end_time: "",
        notes: "",
        custom_price: 0,
        currency_id: "",
      },
    };
  },
  methods: {
    // Tarih biçimlendirme fonksiyonu (API'den gelen tarihleri datetime-local formatına dönüştürmek için)
    formatDateTime(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      return `${year}-${month}-${day}T${hours}:${minutes}`;
    },

    // Kullanıcıları getir
    async fetchUsers() {
      try {
        const response = await axios.get("/admin/mainusers");
        this.users = response.data;
      } catch (error) {
        console.error("Kullanıcılar alınırken hata oluştu:", error);
      }
    },
    // Randevuları getir ve tarih formatlarını düzelt
    async fetchAppointments(userId) {
      try {
        const response = await axios.get(`/admin/cl/appointments/user/${userId}`);
        this.appointments = response.data.map(appointment => ({
          ...appointment,
          start_time: this.formatDateTime(appointment.start_time),
          end_time: this.formatDateTime(appointment.end_time),
        }));
      } catch (error) {
        console.error("Randevular alınırken hata oluştu:", error);
      }
    },
    // Müşterileri getir
    async fetchCustomers(userId) {
      try {
        const response = await axios.get(`/admin/cl/customers/user/${userId}`);
        this.customers = response.data;
      } catch (error) {
        console.error("Müşteriler alınırken hata oluştu:", error);
      }
    },
    // Uzmanları getir
    async fetchExperts(userId) {
      try {
        const response = await axios.get(`/admin/cl/experts/user/${userId}`);
        this.experts = response.data;
      } catch (error) {
        console.error("Uzmanlar alınırken hata oluştu:", error);
      }
    },
    // Hizmetleri getir
    async fetchServices(userId) {
      try {
        const response = await axios.get(`/admin/cl/services/user/${userId}`);
        this.services = response.data;
      } catch (error) {
        console.error("Hizmetler alınırken hata oluştu:", error);
      }
    },
    // Para birimlerini getir
    async fetchCurrencies() {
      try {
        const response = await axios.get("/auth/currencies");
        this.currencies = response.data;
      } catch (error) {
        console.error("Para birimleri alınırken hata oluştu:", error);
      }
    },
    // Seçilen hizmetlerin fiyatlarının toplamını hesaplayıp newAppointment.custom_price alanına koyar
    recalculatePrice() {
      let totalPrice = 0;
      this.selectedServices.forEach(serviceId => {
        const service = this.services.find(s => s.id === serviceId);
        if (service) {
          totalPrice += Number(service.price);
        }
      });
      this.newAppointment.custom_price = totalPrice;
    },

    // Tarihi UTC formatına çevirme
    convertToUTC(localDateTime) {
      const date = new Date(localDateTime);
      return date.toISOString();
    },

    // Yeni randevu ekle
    async addAppointment() {
      console.log("Seçilen Hizmetler:", this.selectedServices);

      // Hizmet verilerini backend'e toplu göndermek için
      const servicesData = this.selectedServices.map(serviceId => {
        const service = this.services.find(s => s.id === serviceId);
        return {
          service_id: service.id,
          quantity: 1,
          price: service.price,
          currency_id: service.currency_id,
        };
      });

      try {
        const appointmentData = {
          ...this.newAppointment,
          user_id: this.selectedUser.user_id,
          start_time: this.convertToUTC(this.newAppointment.start_time),
          end_time: this.convertToUTC(this.newAppointment.end_time),
          services: servicesData, // Hizmet bilgilerini backend'e gönderiyoruz
        };

        console.log("Gönderilen Randevu Verisi:", appointmentData);

        const appointmentResponse = await axios.post("/admin/cl/appointments", appointmentData);
        const appointmentId = appointmentResponse.data.id;
console.log("Oluşan Randevu ID:", appointmentId);

        

        alert("Randevu başarıyla eklendi.");
        this.fetchAppointments(this.selectedUser.user_id);

      } catch (error) {
        console.error("Randevu eklenirken hata oluştu:", error);
      }
    },

    // Randevuyu sil
    async deleteAppointment(appointmentId) {
      if (confirm("Bu randevuyu silmek istediğinizden emin misiniz?")) {
        try {
          await axios.delete(`/admin/cl/appointments/${appointmentId}`);
          this.appointments = this.appointments.filter(a => a.id !== appointmentId);
          alert("Randevu başarıyla silindi.");
        } catch (error) {
          console.error("Randevu silinirken hata oluştu:", error);
        }
      }
    },

    // Randevuyu güncelle
    async updateAppointment(appointment) {
      try {
        await axios.put(`/admin/cl/appointments/${appointment.id}`, appointment);
        alert("Randevu başarıyla güncellendi.");
      } catch (error) {
        console.error("Randevu güncellenirken hata oluştu:", error);
      }
    },

    // Kullanıcı arama filtrasyonu
    filterUsers() {
      if (this.searchQuery.trim() === "") {
        this.filteredUsers = [];
        return;
      }
      this.filteredUsers = this.users.filter(user => {
        const userName = user.user_name?.toLowerCase() || "";
        const userId = user.user_id?.toString() || "";
        return (
          userName.includes(this.searchQuery.toLowerCase()) ||
          userId.includes(this.searchQuery)
        );
      });
    },

    // Kullanıcı seçimi
    async selectUser(user) {
      this.selectedUser = user;
      this.searchQuery = "";
      this.filteredUsers = [];
      await this.fetchAppointments(user.user_id);
      await this.fetchCustomers(user.user_id);
      await this.fetchExperts(user.user_id);
      await this.fetchServices(user.user_id);
    },
  },

  mounted() {
    this.fetchUsers();
    this.fetchCurrencies();
  },
};
</script>

<style scoped>
.user-suggestions {
  list-style: none;
  padding: 0;
}

.user-suggestions li {
  cursor: pointer;
  padding: 5px;
  border-bottom: 1px solid #242424;
}

.user-suggestions li:hover {
  background-color: #000000;
}
 
</style>
