<template>
    <div class="all-customers">
      <h3>Tüm Müşteriler</h3>
      <table class="customers-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Uzman Kullanıcı</th>
            <th>Adı</th>
            <th>Email</th>
            <th>Telefon</th>
            <th>Notlar</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="customer in customers" :key="customer.id">
            <td>{{ customer.id }}</td>
            <td>{{ customer.user_id }}</td>
            <td>{{ customer.name }}</td>
            <td>{{ customer.email }}</td>
            <td>{{ customer.phone }}</td>
            <td>{{ customer.notes }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    data() {
      return {
        customers: [],  
      };
    },
    methods: {
      async fetchAllCustomers() {
        try {
          const response = await axios.get("/admin/cl/customers");
          this.customers = response.data;
        } catch (error) {
          console.error("Müşteriler alınırken hata oluştu:", error);
        }
      },
    },
    mounted() {
      this.fetchAllCustomers();
    },
  };
  </script>
   