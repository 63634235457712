<template>
  <div class="user-transactions">
    <h3>Kullanıcı Transactions Yönetimi</h3>
    <input v-model="searchQuery" placeholder="Kullanıcı ID veya Adı ara" @input="filterUsers" />
    <ul v-if="filteredUsers.length">
      <li v-for="user in filteredUsers" :key="user.user_id" @click="selectUser(user)">
        {{ user.user_name }} (ID: {{ user.user_id }})
      </li>
    </ul>

    <div v-if="selectedUser">
      <h4>Transactions - {{ selectedUser.user_name }}</h4>
      <table>
        <thead>
          <tr>
            <th>Tür</th>
            <th>Kategori</th>
            <th>Tutar</th>
            <th>Para Birimi</th>
            <th>Tarih</th>
            <th>İşlemler</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="transaction in transactions" :key="transaction.id">
            <td>{{ transaction.type }}</td>
            <td>{{ getCategoryName(transaction.category_id) }}</td>
            <td>{{ transaction.amount }}</td>
            <td>{{ getCurrencyName(transaction.currency_id) }}</td>
            <td>{{ formatDate(transaction.transaction_date) }}</td>
            <td>
              <button @click="deleteTransaction(transaction.id)">Sil</button>
            </td>
          </tr>
        </tbody>
      </table>

      <h4>Yeni Transaction Ekle</h4>
      <form @submit.prevent="addTransaction">
        <label for="space_id">Space</label>
        <select v-model="newTransaction.space_id" required>
          <option value="">Seç</option>
          <option v-for="space in userSpaces" :key="space.id" :value="space.id">
            {{ space.name }}
          </option>
        </select>

        <label for="type">Tür</label>
        <select v-model="newTransaction.type" required>
          <option value="">Seç</option>
          <option value="income">Gelir</option>
          <option value="expense">Gider</option>
        </select>

        <label for="category_id">Kategori</label>
        <select v-model="newTransaction.category_id" required>
          <option value="">Seç</option>
          <option v-for="category in userCategories" :key="category.id" :value="category.id">
            {{ category.name }}
          </option>
        </select>

        <label for="amount">Tutar</label>
        <input v-model.number="newTransaction.amount" type="number" placeholder="Tutar" required />

        <label for="currency_id">Para Birimi</label>
        <select v-model="newTransaction.currency_id" required>
          <option v-for="currency in currencies" :key="currency.currency_id" :value="currency.currency_id">
            {{ currency.currency_symbol }} - {{ currency.currency_name }}
          </option>
        </select>

        <label for="transaction_date">Tarih</label>
        <input v-model="newTransaction.transaction_date" type="date" required />

        <label for="description">Açıklama</label>
        <textarea v-model="newTransaction.description" placeholder="Açıklama"></textarea>

        <button type="submit">Ekle</button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      users: [],
      searchQuery: "",
      filteredUsers: [],
      selectedUser: null,
      transactions: [],
      currencies: [],
      userSpaces: [],
      userCategories: [],
      newTransaction: {
        space_id: "",
        type: "",
        category_id: "",
        amount: "",
        currency_id: "",
        transaction_date: "",
        description: "",
      },
    };
  },
  methods: {
    async fetchUsers() {
      try {
        const response = await axios.get("/admin/mainusers");
        this.users = response.data;
      } catch (error) {
        console.error("Kullanıcılar alınırken hata oluştu:", error);
      }
    },
    async fetchCurrencies() {
      try {
        const response = await axios.get("/auth/currencies");
        this.currencies = response.data;
      } catch (error) {
        console.error("Para birimleri alınırken hata oluştu:", error);
      }
    },
    async fetchTransactions(userId) {
      try {
        const response = await axios.get(`/admin/user/${userId}/mt-transactions`);
        this.transactions = response.data.data;
      } catch (error) {
        console.error("MtTransactions alınırken hata oluştu:", error);
      }
    },
    async fetchUserSpaces(userId) {
      try {
        const response = await axios.get(`/admin/user/${userId}/spaces`);
        this.userSpaces = response.data.data;
      } catch (error) {
        console.error("Spaces alınırken hata oluştu:", error);
      }
    },
    async fetchUserCategories(userId) {
      try {
        const response = await axios.get(`/admin/user/${userId}/categories`);
        this.userCategories = response.data.data;
      } catch (error) {
        console.error("Kategoriler alınırken hata oluştu:", error);
      }
    },
    filterUsers() {
      this.filteredUsers = this.users.filter(user =>
        user.user_name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
        user.user_id.toString().includes(this.searchQuery)
      );
    },
    selectUser(user) {
      this.selectedUser = user;
      this.searchQuery = "";
      this.filteredUsers = [];
      this.fetchTransactions(user.user_id);
      this.fetchUserSpaces(user.user_id);
      this.fetchUserCategories(user.user_id);
    },
    async addTransaction() {
      try {
        await axios.post("/admin/mt-transactions", this.newTransaction);
        this.fetchTransactions(this.selectedUser.user_id);
        this.newTransaction = {
          space_id: "",
          type: "",
          category_id: "",
          amount: "",
          currency_id: "",
          transaction_date: "",
          description: "",
        };
      } catch (error) {
        console.error("Transaction eklenirken hata oluştu:", error);
      }
    },
    async deleteTransaction(transactionId) {
      if (confirm("Bu transaction'ı silmek istediğinizden emin misiniz?")) {
        try {
          await axios.delete(`/admin/mt-transactions/${transactionId}`);
          this.transactions = this.transactions.filter(t => t.id !== transactionId);
        } catch (error) {
          console.error("Transaction silinirken hata oluştu:", error);
        }
      }
    },
    getCurrencyName(currencyId) {
      const currency = this.currencies.find(c => c.currency_id === currencyId);
      return currency ? `${currency.currency_symbol} - ${currency.currency_name}` : "Bilinmiyor";
    },
    getCategoryName(categoryId) {
      const category = this.userCategories.find(c => c.id === categoryId);
      return category ? category.name : "Bilinmiyor";
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString();
    },
  },
  mounted() {
    this.fetchUsers();
    this.fetchCurrencies();
  },
};
</script>
