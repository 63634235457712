<template>
    <div class="all-shared-calendars">
      <h3>Tüm Paylaşımlar</h3>
      <table class="shared-calendars-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Sahip ID</th>
            <th>Paylaşılan Kullanıcı ID</th>
            <th>Erişim Seviyesi</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="calendar in sharedCalendars" :key="calendar.id">
            <td>{{ calendar.id }}</td>
            <td>{{ calendar.calendar_owner_id }}</td>
            <td>{{ calendar.shared_with_user_id }}</td>
            <td>{{ calendar.access_level }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    data() {
      return {
        sharedCalendars: [],
      };
    },
    methods: {
      async fetchAllSharedCalendars() {
        try {
          const response = await axios.get("/admin/cl/sharedcalendars");
          this.sharedCalendars = response.data;
        } catch (error) {
          console.error("Paylaşımlar alınırken hata oluştu:", error);
        }
      },
    },
    mounted() {
      this.fetchAllSharedCalendars();
    },
  };
  </script>
   