<template>
    <div class="all-experts">
      <h3>Tüm Uzmanlar</h3>
      <table class="experts-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Kullanıcı</th>
            <th>Adı</th>
            <th>Email</th>
            <th>Telefon</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="expert in experts" :key="expert.id">
            <td>{{ expert.id }}</td>
            <td>{{ expert.user_id }}</td>
            <td>{{ expert.name }}</td>
            <td>{{ expert.email }}</td>
            <td>{{ expert.phone }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    data() {
      return {
        experts: [], 
      };
    },
    methods: {
      async fetchAllExperts() {
        try {
          const response = await axios.get("/admin/cl/experts");
          this.experts = response.data;
        } catch (error) {
          console.error("Uzmanlar alınırken hata oluştu:", error);
        }
      },
    },
    mounted() {
      this.fetchAllExperts();
    },
  };
  </script>
   