<template>
    <div class="all-calendar-settings">
      <h3>Tüm Takvim Ayarları</h3>
      <table class="calendar-settings-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Kullanıcı ID</th>
            <th>Çalışma Saatleri</th>
            <th>Tatil Günleri</th>
            <th>Herkese Açık mı?</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="setting in calendarSettings" :key="setting.id">
            <td>{{ setting.id }}</td>
            <td>{{ setting.user_id }}</td>
            <td>{{ formatJson(setting.working_hours) }}</td>
            <td>{{ formatJson(setting.holidays) }}</td>
            <td>{{ setting.is_public ? "Evet" : "Hayır" }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    data() {
      return {
        calendarSettings: [], 
      };
    },
    methods: {
      async fetchAllCalendarSettings() {
        try {
          const response = await axios.get("/admin/cl/calendarsettings");
          this.calendarSettings = response.data;
        } catch (error) {
          console.error("Takvim ayarları alınırken hata oluştu:", error);
        }
      },
      formatJson(json) {
        return JSON.stringify(json, null, 2); 
      },
    },
    mounted() {
      this.fetchAllCalendarSettings();
    },
  };
  </script>
   