<template>
  <div class="showroom-management">
    <h3>Showroom Yönetimi</h3>

    <h4>{{ isEdit ? "Showroom Düzenle" : "Yeni Showroom Ekle" }}</h4>
    <form @submit.prevent="isEdit ? updateShowroom() : createShowroom()">
      <label for="order_no">Sıra Numarası</label>
      <input v-model="selectedShowroom.order_no" type="number" id="order_no" placeholder="Sıra Numarası" required />

      <label for="name">Adı</label>
      <input v-model="selectedShowroom.name" type="text" id="name" placeholder="Showroom Adı" required />

      <label for="address">Adres</label>
      <textarea v-model="selectedShowroom.address" id="address" placeholder="Adres" required></textarea>

      <label for="instagram">Instagram</label>
      <input v-model="selectedShowroom.instagram" type="text" id="instagram" placeholder="Instagram Linki" />

      <label for="phone">Telefon</label>
      <input v-model="selectedShowroom.phone" type="text" id="phone" placeholder="Telefon Numarası" />

      <label>
        <input type="checkbox" v-model="selectedShowroom.is_active" /> Aktif mi?
      </label>

      <button type="submit">{{ isEdit ? "Güncelle" : "Ekle" }}</button>
      <button v-if="isEdit" @click="cancelEdit" type="button">İptal</button>
    </form>

    <div class="table-header">
      <h4>Showroom Listesi</h4>
      <label>
        <input type="checkbox" v-model="showDeleteButtons" /> Silme Butonlarını Göster
      </label>
    </div>

    <table class="showroom-table">
      <thead>
        <tr>
          <th>Sıra No</th>
          <th>Adı</th>
          <th>Adres</th>
          <th>Instagram</th>
          <th>Telefon</th>
          <th>Aktif</th>
          <th>İşlemler</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="showroom in showrooms" :key="showroom.id">
          <td>{{ showroom.order_no }}</td>
          <td>{{ showroom.name }}</td>
          <td>{{ showroom.address }}</td>
          <td>{{ showroom.instagram || "Yok" }}</td>
          <td>{{ showroom.phone || "Yok" }}</td>
          <td>{{ showroom.is_active ? "Evet" : "Hayır" }}</td>
          <td>
            <button @click="editShowroom(showroom)">Düzenle</button>
            <button v-if="showDeleteButtons" @click="confirmDelete(showroom.id)" class="delete-button">Sil</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      showrooms: [], 
      selectedShowroom: {
        id: null,
        order_no: "",
        name: "",
        address: "",
        instagram: "",
        phone: "",
        is_active: true,
      },
      isEdit: false,
      showDeleteButtons: false,
    };
  },
  methods: {
    async fetchShowrooms() {
      try {
        const response = await axios.get("/admin/showrooms");
        this.showrooms = response.data.data || [];
        console.log("Showroomlar başarıyla alındı:", this.showrooms);
      } catch (error) {
        console.error("Showroomlar alınırken hata oluştu:", error);
      }
    },
    async createShowroom() {
      try {
        await axios.post("/admin/showroom", this.selectedShowroom);
        this.fetchShowrooms();
        this.resetForm();
        console.log("Showroom başarıyla eklendi.");
      } catch (error) {
        console.error("Showroom eklenirken hata oluştu:", error);
      }
    },
    async updateShowroom() {
      try {
        await axios.put(`/admin/showroom/${this.selectedShowroom.id}`, this.selectedShowroom);
        this.fetchShowrooms();
        this.resetForm();
        console.log("Showroom başarıyla güncellendi.");
      } catch (error) {
        console.error("Showroom güncellenirken hata oluştu:", error);
      }
    },
    async deleteShowroom(id) {
      try {
        await axios.delete(`/admin/showroom/${id}`);
        this.fetchShowrooms();
        console.log("Showroom başarıyla silindi.");
      } catch (error) {
        console.error("Showroom silinirken hata oluştu:", error);
      }
    },
    editShowroom(showroom) {
      this.selectedShowroom = { ...showroom };
      this.isEdit = true;
    },
    confirmDelete(id) {
      if (confirm("Bu showroomu silmek istediğinizden emin misiniz?")) {
        this.deleteShowroom(id);
      }
    },
    resetForm() {
      this.selectedShowroom = {
        id: null,
        order_no: "",
        name: "",
        address: "",
        instagram: "",
        phone: "",
        is_active: true,
      };
      this.isEdit = false;
    },
    cancelEdit() {
      this.resetForm();
    },
  },
  mounted() {
    this.fetchShowrooms();
  },
};
</script>

<style scoped>
.showroom-management {
  padding: 20px;
}
 
</style>
