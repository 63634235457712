<template>
    <div class="master-level-management">
      <h3>Master Seviyesi Yönetimi</h3>
  
      <h4>{{ isEdit ? "Master Seviyesi Düzenle" : "Yeni Master Seviyesi Ekle" }}</h4>
      <form @submit.prevent="isEdit ? updateMasterLevel() : createMasterLevel()">
        <input v-model="selectedLevel.title" placeholder="Seviye Başlığı" required />
  
        <textarea
          v-model="selectedLevel.description"
          placeholder="Seviye Açıklaması"
          rows="4"
        ></textarea>
  
        <textarea
          v-model="selectedLevel.details"
          placeholder="Seviye Detayları"
          rows="4"
        ></textarea>
  
        <div>
          <label for="icon">Seviye İkonu:</label>
          <input
            class="upload-button"
            type="file"
            id="icon"
            @change="handleFileUpload($event)"
          />
          <img
            v-if="preview.icon"
            :src="preview.icon"
            alt="Seviye İkonu"
            class="image-preview"
          />
        </div>
  
        <button type="submit">{{ isEdit ? "Güncelle" : "Ekle" }}</button>
        <button v-if="isEdit" @click="cancelEdit" type="button">İptal</button>
      </form>
  
      <div class="table-header">
        <h4>Master Seviyeleri</h4>
        <label>
          <input type="checkbox" v-model="showDeleteButtons" />
          Silme Butonlarını Göster
        </label>
      </div>
  
      <table class="master-level-table">
        <thead>
          <tr>
            <th>Seviye Başlığı</th>
            <th>Açıklama</th>
            <th>Detaylar</th>
            <th>İkon</th>
            <th>İşlemler</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="level in masterLevels" :key="level.level_id">
            <td>{{ level.title }}</td>
            <td>{{ level.description || "Yok" }}</td>
            <td>{{ level.details || "Yok" }}</td>
            <td>
              <img
                v-if="level.icon_link"
                :src="level.icon_link"
                alt="Seviye İkonu"
                class="image-preview"
              />
              <span v-else>Yok</span>
            </td>
            <td>
              <button @click="editMasterLevel(level)">Düzenle</button>
              <button
                v-if="showDeleteButtons"
                class="delete-button"
                @click="confirmDelete(level.level_id)"
              >
                Sil
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    data() {
      return {
        masterLevels: [],  
        selectedLevel: {
          level_id: null,
          title: "",
          description: "",
          details: "",
          icon: null,  
        },
        preview: {
          icon: null,  
        },
        isEdit: false, 
        showDeleteButtons: false,  
      };
    },
    methods: {
      async fetchMasterLevels() {
        try {
          const response = await axios.get("/promo/master-levels");
          this.masterLevels = response.data.masterLevels; 
        } catch (error) {
          console.error("Master seviyeleri alınırken hata oluştu:", error);
        }
      },
      handleFileUpload(event) {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
          this.preview.icon = e.target.result;
          this.selectedLevel.icon = file;
        };
        reader.readAsDataURL(file);
      },
      async createMasterLevel() {
        try {
          const formData = new FormData();
          formData.append("title", this.selectedLevel.title);
          formData.append("description", this.selectedLevel.description);
          formData.append("details", this.selectedLevel.details);
          if (this.selectedLevel.icon) {
            formData.append("icon", this.selectedLevel.icon);
          }
  
          await axios.post("/promo/master-level", formData, {
            headers: { "Content-Type": "multipart/form-data" },
          });
  
          this.fetchMasterLevels();
          this.resetForm();
        } catch (error) {
          console.error("Master seviyesi eklenirken hata oluştu:", error);
        }
      },
      async updateMasterLevel() {
        try {
          const formData = new FormData();
          formData.append("title", this.selectedLevel.title);
          formData.append("description", this.selectedLevel.description);
          formData.append("details", this.selectedLevel.details);
          if (this.selectedLevel.icon) {
            formData.append("icon", this.selectedLevel.icon);
          }
  
          await axios.put(`/promo/master-level/${this.selectedLevel.level_id}`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          });
  
          this.fetchMasterLevels();
          this.resetForm();
        } catch (error) {
          console.error("Master seviyesi güncellenirken hata oluştu:", error);
        }
      },
      editMasterLevel(level) {
        this.selectedLevel = { ...level, icon: null };
        this.preview.icon = level.icon_link;
        this.isEdit = true;
      },
      async deleteMasterLevel(id) {
        try {
          await axios.delete(`/promo/master-level/${id}`);
          this.fetchMasterLevels();
        } catch (error) {
          console.error("Master seviyesi silinirken hata oluştu:", error);
        }
      },
      confirmDelete(id) {
        if (confirm("Bu master seviyesini silmek istediğinizden emin misiniz?")) {
          this.deleteMasterLevel(id);
        }
      },
      resetForm() {
        this.selectedLevel = {
          level_id: null,
          title: "",
          description: "",
          details: "",
          icon: null,
        };
        this.preview.icon = null;
        this.isEdit = false;
      },
      cancelEdit() {
        this.resetForm();
      },
    },
    mounted() {
      this.fetchMasterLevels();
    },
  };
  </script> 