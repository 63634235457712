<template>
    <div class="product-price-manager">
      <h3>İşlem Fiyatları Yönetimi</h3>

      <p style="color: red;">Lütfen Dikkat işlemleri SİLMEYİN. Buradaki işlemler farklı siteler tarafından görüntüleniyor. Sadece fiyatları güncelleyin.  </p>
  
      <form @submit.prevent="isEditMode ? updateProductPrice() : addProductPrice()">
        <h4>{{ isEditMode ? 'İşlem Fiyatını Güncelle' : 'Yeni İşlem Fiyatı Ekle' }}</h4>
  
        <label>Ad</label>
        <input v-model="formData.name" placeholder="Ürün Adı" required />
  
        <label>Fiyat</label>
        <input v-model="formData.price" type="number" step="0.01" placeholder="Fiyat" required />
  
        <label>İndirimli Fiyat</label>
        <input v-model="formData.sale_price" type="number" step="0.01" placeholder="İndirimli Fiyat" />
  
        <button type="submit">{{ isEditMode ? 'Güncelle' : 'Ekle' }}</button>
        <button v-if="isEditMode" type="button" @click="cancelEdit">İptal</button>
      </form>
  
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Ad</th>
            <th>Fiyat</th>
            <th>İndirimli Fiyat</th>
            <th>İşlemler</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="product in products" :key="product.id">
            <td>{{ product.id }}</td>
            <td>{{ product.name }}</td>
            <td>{{ product.price }}</td>
            <td>{{ product.sale_price || '—' }}</td>
            <td>
              <button @click="editProduct(product)">Düzenle</button>
              <button @click="deleteProduct(product.id)">Sil</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    data() {
      return {
        products: [],
        isEditMode: false,
        formData: {
          id: null,
          name: "",
          price: null,
          sale_price: null,
        },
      };
    },
    created() {
      this.fetchProducts();
    },
    methods: {
      async fetchProducts() {
        try {
          const response = await axios.get("/admin/refika/productprices");
          this.products = response.data.data || [];
        } catch (error) {
          console.error("Ürün fiyatları alınırken hata oluştu:", error);
        }
      },
      async addProductPrice() {
        try {
          await axios.post("/admin/refika/productprices", this.formData);
          this.fetchProducts();
          this.resetForm();
        } catch (error) {
          console.error("Ürün fiyatı eklenirken hata oluştu:", error);
        }
      },
      async updateProductPrice() {
        try {
          await axios.put(`/admin/refika/productprices/${this.formData.id}`, this.formData);
          this.fetchProducts();
          this.resetForm();
        } catch (error) {
          console.error("Ürün fiyatı güncellenirken hata oluştu:", error);
        }
      },
      async deleteProduct(id) {
        try {
          if (confirm("Bu ürünü silmek istediğinizden emin misiniz?")) {
            await axios.delete(`/admin/refika/productprices/${id}`);
            this.fetchProducts();
          }
        } catch (error) {
          console.error("Ürün fiyatı silinirken hata oluştu:", error);
        }
      },
      editProduct(product) {
        this.isEditMode = true;
        this.formData = { ...product };
      },
      cancelEdit() {
        this.resetForm();
      },
      resetForm() {
        this.isEditMode = false;
        this.formData = {
          id: null,
          name: "",
          price: null,
          sale_price: null,
        };
      },
    },
  };
  </script>
  
  <style scoped>
  .product-price-manager {
    padding: 20px;
  } 
  </style>
  