<template>
    <div class="moderation-logs">
      <h3>Moderasyon Kayıtları</h3>
      <div>
        <select v-model="filterAction" @change="fetchLogs">
          <option value="">Tümü</option>
          <option value="Approved">Onaylandı</option>
          <option value="Rejected">Reddedildi</option>
        </select>
      </div>
      <table>
        <thead>
          <tr>
            <th>Tarih</th>
            <th>İşlem</th>
            <th>Moderatör</th>
            <th>Açıklama</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="log in logs" :key="log.log_id">
            <td>{{ log.created_at }}</td>
            <td>{{ log.action }}</td>
            <td>{{ log.moderator_id }}</td>
            <td>{{ log.reason }}</td>
          </tr>
          <tr v-if="logs.length === 0">
            <td colspan="4">Kayıt bulunamadı.</td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    name: "ModerationLogs",
    data() {
      return {
        logs: [],
        filterAction: "",
      };
    },
    methods: {
      async fetchLogs() {
        try {
          const response = await axios.get("/admin/moderation-logs", {
            params: { action: this.filterAction },
          });
          this.logs = response.data.data || [];
        } catch (error) {
          console.error("Moderasyon kayıtları alınırken hata oluştu:", error);
        }
      },
    },
    mounted() {
      this.fetchLogs();
    },
  };
  </script>
  
  <style scoped>
  .moderation-logs {
    padding: 20px;
  }
  </style>
  