<template>
    <div>

        <div style="color: red; text-align: center;">
             <h2>LÜTFEN DİKKAT!</h2>
             <h3>Büyük veri seti ve toplu işlem otomasyonu yapıyorsunuz.</h3>
             <h3>Olası hataların çözümü database seviyesinde müdehale gerektirebilir.</h3> 
             <h2>Lütfen önce 3-5 soru ile test edin</h2>
             <p>örnek JSON dosyası</p>
             
             <p style="background-color: black; color: green; border-radius: 15px; padding: 2em; border: 1px solid green;">
                 [
               {
                 "question_text": "Ciltteki istenmeyen kahverengi tonlarını düzeltmek için hangi tamamlayıcı renk kullanılır?",
                 "difficulty_level": 2,
                 "is_active": true,
                 "answers": ["Koyu kırmızı", "Yeşil", "Sarı", "Turuncu"],
                 "correct_index": 2
               },
               {
                 "question_text": "İstenmeyen pembe tonları düzeltmek için hangi pigment kullanılır?",
                 "difficulty_level": 2,
                 "is_active": true,
                 "answers": ["Mavi", "Sarı", "Yeşil", "Koyu kırmızı"],
                 "correct_index": 2
               },
               {
                 "question_text": "Renk düzeltme işlemi sırasında, hangi renk doğal bir kahverengi elde etmek için etkili bir şekilde karıştırılabilir?",
                 "difficulty_level": 3,
                 "is_active": true,
                 "answers": ["Sarı ve turuncu", "Yeşil ve kırmızı", "Mavi ve kırmızı", "Sarı ve kırmızı"],
                 "correct_index": 0
               }
             ]
             
             </p>
         </div>


    <div class="bulk-questions">
      <h3>Toplu Soru Ekle</h3>
      <textarea v-model="jsonInput" placeholder="Soruların JSON formatını buraya yapıştırın" rows="15" cols="80"></textarea>
      <button @click="submitQuestions">Soruları Gönder</button>
  
      <div v-if="successMessage" class="success-message">{{ successMessage }}</div>
      <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
    </div> 

  </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        jsonInput: '', // Kullanıcının JSON gireceği alan
        successMessage: '',
        errorMessage: ''
      };
    },
    methods: {
      async submitQuestions() {
        try {
          // Kullanıcıdan gelen JSON'u doğrula
          const parsedData = JSON.parse(this.jsonInput);
          
          if (!Array.isArray(parsedData)) {
            throw new Error('JSON bir dizi olmalı.');
          }
  
          // API çağrısı
          const response = await axios.post('/admin/questions/bulk-create', parsedData);
          
          this.successMessage = response.data.message;
          this.errorMessage = '';
          this.jsonInput = ''; // Başarıyla gönderildiğinde textarea'yı temizle
        } catch (error) {
          this.successMessage = '';
          this.errorMessage = error.response?.data?.message || error.message || 'Bir hata oluştu.';
          console.error('Hata:', error);
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .bulk-questions {
    padding: 20px;
    background-color: #3a0000;
    border-radius: 8px;
    max-width: 600px;
    margin: auto;
  }
  
  textarea {
    width: 100%;
    border: 1px solid #700000;
    border-radius: 4px;
    padding: 10px;
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  button {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  button:hover {
    background-color: #45a049;
  }
  
  .success-message {
    color: green;
    margin-top: 10px;
  }
  
  .error-message {
    color: red;
    margin-top: 10px;
  }
  </style>
  