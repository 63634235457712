<template>
  <div class="static-page-management">
    <h3>Statik Sayfalar</h3>

    <h4>{{ isEdit ? "Sayfayı Düzenle" : "Yeni Sayfa Ekle" }}</h4>
    <form @submit.prevent="isEdit ? updateStaticPage() : createStaticPage()">
      <input v-model="selectedStaticPage.title" placeholder="Sayfa Başlığı" required />
      <input v-model="selectedStaticPage.slug" placeholder="Slug" required />
      <textarea v-model="selectedStaticPage.content" placeholder="İçerik" required></textarea>
      <button type="submit">{{ isEdit ? "Güncelle" : "Sayfa Ekle" }}</button>
      <button v-if="isEdit" @click="cancelEdit" type="button">İptal</button>
    </form>

    <div class="table-header">
      <h4>Tüm Statik Sayfalar</h4>
      <label>
        <input type="checkbox" v-model="showDeleteButtons" /> Silme Butonlarını Göster
      </label>
    </div>
    <div>
      <div class="page" v-for="page in staticPages" :key="page.id">
        <h4>{{ page.title }}</h4>
        <p>{{ page.slug }}</p>
        <p>{{ page.content }}</p> 
        <button @click="editStaticPage(page)">Düzenle</button>
        <button v-if="showDeleteButtons" @click="confirmDelete(page.id)">Sil</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'StaticPageManager',
  data() {
    return {
      staticPages: [],
      selectedStaticPage: {
        id: null,
        title: '',
        slug: '',
        content: '',
      },
      isEdit: false,
      showDeleteButtons: false,
    };
  },
  methods: { 
    async fetchStaticPages() {
      try {
        const response = await axios.get('/promo/staticpages');
        this.staticPages = response.data.staticPages;
      } catch (error) {
        console.error('Statik sayfalar alınırken hata oluştu:', error); 
      }
    }, 
    async createStaticPage() {
      try {
        await axios.post('/promo/staticpage', {
          title: this.selectedStaticPage.title,
          slug: this.selectedStaticPage.slug,
          content: this.selectedStaticPage.content,
        });
        this.fetchStaticPages();
        this.resetForm(); 
      } catch (error) {
        console.error('Statik sayfa eklenirken hata oluştu:', error); 
      }
    },
 
    editStaticPage(page) {
      this.selectedStaticPage = { ...page };
      this.isEdit = true;
    },
 
    async updateStaticPage() {
      try {
        await axios.put(`/promo/staticpage/${this.selectedStaticPage.id}`, {
          title: this.selectedStaticPage.title,
          slug: this.selectedStaticPage.slug,
          content: this.selectedStaticPage.content,
        });
        this.fetchStaticPages();
        this.resetForm(); 
      } catch (error) {
        console.error('Statik sayfa güncellenirken hata oluştu:', error); 
      }
    },
 
    async deleteStaticPage(id) {
      try {
        await axios.delete(`/promo/staticpage/${id}`);
        this.fetchStaticPages(); 
      } catch (error) {
        console.error('Statik sayfa silinirken hata oluştu:', error); 
      }
    }, 
    confirmDelete(id) {
      if (confirm('Bu sayfayı silmek istediğinize emin misiniz?')) {
        this.deleteStaticPage(id);
      }
    },
 
    resetForm() {
      this.selectedStaticPage = {
        id: null,
        title: '',
        slug: '',
        content: '',
      };
      this.isEdit = false;
    },
 
    cancelEdit() {
      this.resetForm();
    },
  },
  mounted() {
    this.fetchStaticPages();
  },
};
</script>
 
<style>
.page {
  margin: 1em;
  padding: 1em;
  background-color: #070707;
}

</style>