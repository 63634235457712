<template>
    <div class="all-appointments">
      <h3>Tüm Randevular</h3>
      <table class="appointments-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Kullanıcı ID</th>
            <th>Uzman ID</th>
            <th>Müşteri ID</th>
            <th>Başlangıç</th>
            <th>Bitiş</th>
            <th>Durum</th>
            <th>Notlar</th>
            <th>Fiyat</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="appointment in appointments" :key="appointment.id">
            <td>{{ appointment.id }}</td>
            <td>{{ appointment.user_id }}</td>
            <td>{{ appointment.expert_id }}</td>
            <td>{{ appointment.customer_id }}</td>
            <td>{{ formatDate(appointment.start_time) }}</td>
            <td>{{ formatDate(appointment.end_time) }}</td>
            <td>{{ appointment.status }}</td>
            <td>{{ appointment.notes }}</td>
            <td>{{ appointment.custom_price }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    data() {
      return {
        appointments: [],  
      };
    },
    methods: {
      async fetchAllAppointments() {
        try {
          const response = await axios.get("/admin/cl/appointments");
          this.appointments = response.data;
        } catch (error) {
          console.error("Randevular alınırken hata oluştu:", error);
        }
      },
      formatDate(date) {
        return new Date(date).toLocaleString(); 
      },
    },
    mounted() {
      this.fetchAllAppointments();
    },
  };
  </script>
   