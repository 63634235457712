<template>
    <div class="certificates-management">
      <h3>Sertifika Yönetimi</h3>
  
      <h4>{{ isEdit ? "Sertifika Düzenle" : "Yeni Sertifika Ekle" }}</h4>
      <form @submit.prevent="isEdit ? updateCertificate() : createCertificate()">
        <input v-model="selectedCertificate.name" placeholder="Sertifika Adı" required />
        <textarea v-model="selectedCertificate.details" placeholder="Sertifika Detayları"></textarea>
  
        <div>
          <label for="image">Sertifika Görseli:</label>
          <input class="upload-button" type="file" id="image" @change="handleFileUpload" />
          <img v-if="preview.image" :src="preview.image" alt="Sertifika Görseli" class="image-preview" />
        </div>
  
        <button type="submit">{{ isEdit ? "Güncelle" : "Ekle" }}</button>
        <button v-if="isEdit" @click="cancelEdit" type="button">İptal</button>
      </form>
  
      <div class="table-header">
        <h4>Tüm Sertifikalar</h4>
        <label>
          <input type="checkbox" v-model="showDeleteButtons" /> Silme Butonlarını Göster
        </label>
      </div>
  
      <table class="certificates-table">
        <thead>
          <tr>
            <th>Adı</th>
            <th>Detaylar</th>
            <th>Görsel</th>
            <th>Düzenle</th>
            <th v-if="showDeleteButtons">Sil</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="certificate in certificates" :key="certificate.certificate_id">
            <td>{{ certificate.name }}</td>
            <td>{{ certificate.details || "Detay Yok" }}</td>
            <td>
              <img 
                v-if="certificate.image_link" 
                :src="certificate.image_link" 
                alt="Sertifika Görseli" 
                class="image-preview" 
              />
              <span v-else>Yok</span>
            </td>
            <td><button @click="editCertificate(certificate)">Düzenle</button></td>
            <td v-if="showDeleteButtons">
              <button class="deletebutton" @click="confirmDelete(certificate.certificate_id)">Sil</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        certificates: [],
        selectedCertificate: {
          certificate_id: null,
          name: '',
          details: '',
          image: null,
        },
        preview: {
          image: null,
        },
        isEdit: false,
        showDeleteButtons: false,
      };
    },
    methods: {
      async fetchCertificates() {
        try {
          const response = await axios.get('/admin/certificates');
          this.certificates = response.data.certificates;
        } catch (error) {
          console.error('Sertifikalar alınırken hata oluştu:', error);
        }
      },
  
      handleFileUpload(event) {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
          this.preview.image = e.target.result;
          this.selectedCertificate.image = file;
        };
        reader.readAsDataURL(file);
      },
  
      async createCertificate() {
        try {
          const formData = new FormData();
          formData.append('name', this.selectedCertificate.name);
          formData.append('details', this.selectedCertificate.details || '');
          if (this.selectedCertificate.image) {
            formData.append('image', this.selectedCertificate.image);
          }
  
          await axios.post('/admin/certificates', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });
          this.fetchCertificates();
          this.resetForm();
        } catch (error) {
          console.error('Sertifika eklenirken hata oluştu:', error);
        }
      },
  
      async updateCertificate() {
        try {
          const formData = new FormData();
          formData.append('name', this.selectedCertificate.name);
          formData.append('details', this.selectedCertificate.details || '');
          if (this.selectedCertificate.image) {
            formData.append('image', this.selectedCertificate.image);
          }
  
          await axios.put(`/admin/certificates/${this.selectedCertificate.certificate_id}`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });
          this.fetchCertificates();
          this.resetForm();
        } catch (error) {
          console.error('Sertifika güncellenirken hata oluştu:', error);
        }
      },
  
      editCertificate(certificate) {
        this.selectedCertificate = {
          ...certificate,
          image: null,
        };
        this.preview.image = certificate.image_link;
        this.isEdit = true;
      },
  
      async deleteCertificate(id) {
        try {
          await axios.delete(`/admin/certificates/${id}`);
          this.fetchCertificates();
        } catch (error) {
          console.error('Sertifika silinirken hata oluştu:', error);
        }
      },
  
      confirmDelete(id) {
        if (confirm('Bu sertifikayı silmek istediğinizden emin misiniz?')) {
          this.deleteCertificate(id);
        }
      },
  
      resetForm() {
        this.selectedCertificate = {
          certificate_id: null,
          name: '',
          details: '',
          image: null,
        };
        this.preview.image = null;
        this.isEdit = false;
      },
  
      cancelEdit() {
        this.resetForm();
      },
    },
    mounted() {
      this.fetchCertificates();
    },
  };
  </script>
   